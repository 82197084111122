import { AnyPropsType } from '~/AnyPropsType';
import React from 'react';

import BreadcrumbsAnchor from './anchor';
import BreadcrumbsProvider from './provider';

const createBreadcrumbs = () => {
  const Context = React.createContext({});

  const withContext =
    (Component) =>
    ({ ...props }) =>
      <Component context={Context} {...props} />;

  const Breadcrumbs = ({ components, ...props }: AnyPropsType) => {
    const { Item, Divider, Container } = components;

    return (
      <Context.Consumer>
        {(value: any) => (
          <Container {...props}>
            {value.routes.map((item, i, rts) => (
              <React.Fragment key={`breadcrumbs_${item.path}`}>
                <Item item={item} />
                {i + 1 !== rts.length && <Divider />}
              </React.Fragment>
            ))}
          </Container>
        )}
      </Context.Consumer>
    );
  };

  // Breadcrumbs.propTypes = {
  //   components: PropTypes.shape({
  //     Item: PropTypes.func,
  //     Divider: PropTypes.func,
  //     Container: PropTypes.func,
  //   }).isRequired,
  // };

  return {
    Provider: withContext(BreadcrumbsProvider),
    Anchor: withContext(BreadcrumbsAnchor),
    Breadcrumbs,
  };
};

export default createBreadcrumbs;
