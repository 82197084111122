import React, { useState } from 'react';
import { AnyPropsType } from '~/AnyPropsType';
import Loader from '~/app/components/core/preloader/index';
import { scopeLabels } from '~/app/constants/app/options';
import { reportComparisonPath, reportComparisonsPath, reportPagePath } from '~/app/constants/url/auditor';
import { formatDate } from '~/app/utils/format-data';
import { Region } from '~/data/utils/types';
import cx from 'classnames';
import { Row, Text } from '~/ui/index.js';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import { Link, useHistory } from 'react-router-dom';
import TableRowsLoader from '~/ui/components/mui-table/table-loader';
import TablePagination from '~/ui/components/table-pagination/index';
import s from './styles.module.scss';
import ZoomInRounded from '@mui/icons-material/Search';
import Modal from '~/ui/components/modal/index';

// TODO
const getComparisonStatusStamp = (comparison) => {
  if (comparison?.get('status') === 'Finished')
    return (
      <div className={cx(s.statusStamp, s.statusStampSuccess)}> {comparison.getIn(['statusInfo', 'message'])}</div>
    );

  if (comparison?.getIn(['statusInfo', 'isFailed']))
    return <div className={cx(s.statusStamp, s.statusStampError)}>{comparison.getIn(['statusInfo', 'message'])}</div>;

  return (
    <div className={cx(s.statusStamp, s.statusStampProcessing)}> {comparison.getIn(['statusInfo', 'message'])}</div>
  );
};

const TechnicalFileInfoModal = ({ files, open, handleClose }) => (
  <Modal
    open={open}
    closeIcon
    onClose={handleClose}
    modalTitle={<Text weight={Text.weight.medium}>Particles Version Info</Text>}
  >
    {files?.map((file) => (
      <Box className={s.particlesInfoFile}>
        <Text size={Text.size.s}>{file.name}</Text>
        <ul>
          {file.infoParticles?.validationInfo?.map((validationInfo) => (
            <li className={s.particlesInfoFileTaxonomyListItem}>
              {validationInfo.taxonomy}
              <ul>
                <li>
                  <Text size={Text.size.s}>Container Version: {validationInfo.containerVersion ?? 'unknown'}</Text>
                </li>
                <li>
                  <Text size={Text.size.s}>Validation Version: {validationInfo.validationVersion ?? 'unknown'}</Text>
                </li>
              </ul>
            </li>
          ))}
        </ul>
      </Box>
    ))}
  </Modal>
);

const Report = ({
  client,
  report,
  isClientLoaded,
  isReportLoaded,
  comparisons,
  totalItems,
  perPage,
  onPagination,
  page,
  isComparisonsLoaded,
  onSort,
  onDownloadExcel,
  sort,
}: AnyPropsType) => {
  const history = useHistory();
  const [showTechnicalInfoModal, setShowTechnicalInfoModal] = useState(false);

  const files =
    report?.get('files').size &&
    report
      .get('files')
      .toJS()
      .flatMap((file) => file.infoSa?.names?.map((name) => ({ ...file, name })) || file)
      .filter((file) => file.name);

  return isClientLoaded && isReportLoaded ? (
    <div className={s.report}>
      <Row className={s.reportTitle}>
        <Text size={Text.size.xl} weight={Text.weight.semiMedium} color={Text.color.colorTextDark}>
          {`Report ${report.get('periodEnd') && formatDate(report.get('periodEnd'))} - ${client.get('name')}`}
        </Text>
      </Row>

      <Box className={cx(s.reportInfo, s.reportInfoGeneral)}>
        <Text weight={Text.weight.semiMedium} className={s.reportInfoGeneralTitle}>
          General information
        </Text>
        <Box display="flex" flexDirection="row" marginLeft="16px">
          <Box className={s.reportInfoItem}>
            <Text className={s.reportInfoItemTitle}>Period End</Text>
            <Text className={s.reportInfoItemDesc}>
              {report.get('periodEnd') && formatDate(report.get('periodEnd'))}
            </Text>
          </Box>
          <Box className={s.reportInfoItem}>
            <Text className={s.reportInfoItemTitle}>Scope</Text>
            <Text className={s.reportInfoItemDesc}>
              {scopeLabels[report.getIn(['infoParticles', 'scope']) || report.getIn(['infoSa', 'scope'])]}
            </Text>
          </Box>
          <Box className={s.reportInfoItem}>
            <Text className={s.reportInfoItemTitle}>Client report</Text>
            <Text className={s.reportInfoItemDesc}>{client.get('name')}</Text>
          </Box>
        </Box>
      </Box>
      <Box className={s.reportInfo}>
        <Box className={s.reportInfoHeader}>
          <Text weight={Text.weight.semiMedium} className={s.reportInfoTitle}>
            Uploaded files
          </Text>
          <Button
            className={s.reportInfoTechnicalInfoButton}
            onClick={() => setShowTechnicalInfoModal(true)}
            // eslint-disable-next-line react/jsx-no-undef
            startIcon={<ZoomInRounded />}
          />
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="40%" key="periodEnd">
                  <Text weight={Text.weight.semiMedium} size={Text.size.s}>
                    File
                  </Text>
                </TableCell>
                <TableCell width="20%" key="type">
                  <Text weight={Text.weight.semiMedium} size={Text.size.s}>
                    Date
                  </Text>
                </TableCell>
                {client?.get('region') === Region.Belgium && (
                  <>
                    <TableCell width="20%" key="visualExcel">
                      <Text weight={Text.weight.semiMedium} size={Text.size.s}>
                        Visual Excel
                      </Text>
                    </TableCell>

                    <TableCell width="20%" key="action">
                      <Text weight={Text.weight.semiMedium} size={Text.size.s}>
                        Action
                      </Text>
                    </TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            {!!report.get('files').size && (
              <TableBody>
                {files.map((file) => (
                  <TableRow>
                    <TableCell width="40%" className={s.filesTableFileCol}>
                      <Text size={Text.size.s}>{file.name}</Text>
                      <Text size={Text.size.xs} color={Text.color.light}>
                        {file?.infoParticles?.validationInfo.map((v) => v.taxonomy).join(', ')}
                      </Text>
                    </TableCell>
                    <TableCell width="20%">
                      <Text size={Text.size.s}>{file.inserted && formatDate(file.inserted)}</Text>
                    </TableCell>
                    {client?.get('region') === Region.Belgium && (
                      <>
                        <TableCell width="20%">
                          {file.infoParticles?.visualExcelFileName ? (
                            <Button
                              onClick={() => onDownloadExcel(file.id, file.infoParticles?.visualExcelFileName)}
                              size="small"
                              variant="text"
                              className={s.filesTableTextButton}
                              startIcon={<FileDownloadOutlinedIcon />}
                            >
                              <Text size={Text.size.s}>download</Text>
                            </Button>
                          ) : (
                            <Text size={Text.size.s}>processing</Text>
                          )}
                        </TableCell>

                        <TableCell width="15%">
                          <Button
                            onClick={() => onDownloadExcel(file.id, file.infoParticles?.visualExcelFileName)}
                            size="small"
                            variant="text"
                            className={s.filesTableTextButton}
                            startIcon={<CompareArrowsOutlinedIcon />}
                            href={reportComparisonPath(client.get('id'), report.get('id'), file.id)}
                          >
                            <Text size={Text.size.s}>compare</Text>
                          </Button>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>
      {client?.get('region') === Region.Belgium && (
        <Box className={s.reportInfo}>
          <Text weight={Text.weight.semiMedium} className={s.reportInfoTitle}>
            Comparisons
          </Text>

          <TableContainer>
            <Table>
              <TableHead>
                <TableCell width="40%" key="periodEnd">
                  <Text size={Text.size.l}>Names</Text>
                </TableCell>
                <TableCell width="20%" key="inserted">
                  <TableSortLabel
                    active={sort.key === 'inserted'}
                    direction={sort.direction}
                    onClick={() => onSort('inserted')}
                  >
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      Date
                    </Text>
                  </TableSortLabel>
                </TableCell>
                <TableCell key="status">
                  <TableSortLabel
                    active={sort.key === 'status'}
                    direction={sort.direction}
                    onClick={() => onSort('status')}
                  >
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      Status
                    </Text>
                  </TableSortLabel>
                </TableCell>
              </TableHead>
              <TableBody>
                {isComparisonsLoaded ? (
                  comparisons.map((comparison) => (
                    <TableRow
                      component={Link}
                      to={reportComparisonsPath(client.get('id'), report.get('id'), comparison.get('id'))}
                      hover
                    >
                      <TableCell>
                        <Text size={Text.size.s}>{comparison.get('name')}</Text>
                      </TableCell>
                      <TableCell>
                        <Text size={Text.size.s}>
                          {comparison.get('inserted') && formatDate(comparison.get('inserted'))}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text size={Text.size.s}>{getComparisonStatusStamp(comparison)}</Text>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRowsLoader rowsNum={perPage} colsNum={3} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={s.reportPagination}>
            <TablePagination
              perPage={perPage}
              totalItems={totalItems}
              page={page}
              handleChangePage={(p) => {
                history.push(reportPagePath(client.get('id'), report.get('id'), p + 1));
              }}
              onRowsPerPageChange={onPagination}
              hideRowsOptions
            />
          </div>
        </Box>
      )}
      <TechnicalFileInfoModal
        files={files}
        open={showTechnicalInfoModal}
        handleClose={() => setShowTechnicalInfoModal(false)}
      />
    </div>
  ) : (
    <Loader />
  );
};

export default Report;
