import * as constants from '~/data/constants/approvals';

export const fetchList =
  (filter) =>
  async (dispatch, _, { api, schema, normalize }) => {
    const newFilter = filter;
    if (newFilter) {
      Object.keys(newFilter).forEach((key) => {
        if (newFilter[key] === undefined) {
          delete newFilter[key];
        }
      });
    }
    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const res = await api.get(`reports/approvals`, {
        params: newFilter,
      });
      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        count: res.data.count,
        payload: normalize(res.data.items, [schema.approval]),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
    }
  };

export const rejectReport =
  (requestId, values, actions) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.REJECTION_START });
    actions.setSubmitting(true);
    try {
      await api.patch(`reports/approvals/${requestId}/reject`, values);
      actions.setSubmitting(false);

      dispatch({
        type: constants.REJECTION_SUCCESS,
      });

      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.REJECTION_FAILED });
      actions.setSubmitting(false);

      throw e;
    }
  };

export const approveReport =
  (requestId) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.APPROVAL_START });
    try {
      await api.patch(`reports/approvals/${requestId}/approve`);

      dispatch({
        type: constants.APPROVAL_SUCCESS,
      });

      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.APPROVAL_FAILED });

      throw e;
    }
  };

export const clear = () => ({ type: constants.CLEAR });

export const clearSingle = () => ({ type: constants.CLEAR_SINGLE });
