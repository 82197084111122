import React from 'react';
import { Region } from '~/data/utils/types';
import { Box, Button, TextField } from '@mui/material';
import { SelectInput, Text } from '~/ui/index.js';
import { Field, Form, Formik } from 'formik';
import { UserSchema } from '~/data/index.js';
import FormField from '~/app/components/core/form/field';

import s from './styles.module.scss';
import Modal from '~/ui/components/modal/index';

const AddUserPopUp = ({ handleClose, handleAddUser, isSuperAdmin }) => {
  const regionOptions = [
    ...Object.values(Region).map((x) => ({
      value: x,
      label: x.split(/(?=[A-Z])/).join(' '),
    })),
    { value: null, label: 'No region admin' },
  ];

  const onSubmit = async (values, formikActions) => {
    const res = await handleAddUser(values, formikActions);
    if (res) {
      handleClose();
    }
  };
  return (
    <Modal open closeIcon onClose={handleClose} modalTitle={<Text weight={Text.weight.medium}>Create a new user</Text>}>
      <Formik
        onSubmit={onSubmit}
        validationSchema={UserSchema}
        initialValues={{
          firstName: '',
          familyName: '',
          email: '',
          adminRegion: null,
        }}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form className={s.pageForm}>
            <Box>
              <Field
                component={FormField}
                id="firstName"
                name="firstName"
                placeholder="First name"
                label="First name"
                inputComponent={TextField}
                fullWidth
              />
            </Box>
            <Box>
              <Field
                component={FormField}
                id="familyName"
                name="familyName"
                placeholder="Family name"
                label="Family name"
                inputComponent={TextField}
                fullWidth
              />
            </Box>
            <Box>
              <Field
                component={FormField}
                id="email"
                name="email"
                placeholder="Email"
                label="Email"
                inputComponent={TextField}
                fullWidth
              />
            </Box>

            {isSuperAdmin && (
              <Box>
                <div className={s.regionalLabel}>
                  <Text size={Text.size.xxs}>Regional admin</Text>
                </div>
                <Field
                  className={s.formField}
                  inputComponent={SelectInput}
                  options={regionOptions}
                  component={FormField}
                  id="adminRegion"
                  name="adminRegion"
                  placeholder="Select admin type"
                  onChangeAdapter={(value) => value.value}
                  onBlurAdapter={(value) => value}
                  valueAdapter={(value) => regionOptions.find((x) => x.value === value)}
                />
              </Box>
            )}

            <Box className={s.formButtonsWrap}>
              <Button variant="outlined" data-cy="clientEditCancel" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" data-cy="saveClient" type="submit" disabled={isSubmitting}>
                Add user
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddUserPopUp;
