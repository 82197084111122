import { approvalsPagePath } from '~/app/constants/url/auditor';
import { onFilterHelper } from '~/app/utils/filter-helpers';
import { onPaginationHelper } from '~/app/utils/pagination-helpers';
import { onSortingHelper } from '~/app/utils/sorting-helpers';
import { actions, selectors } from '~/data/index.js';
import { useAppDispatch, useAppSelector } from '~/data/utils/hooks';
import React, { useEffect, useState } from 'react';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import { formatDate } from '~/app/utils/format-data';
import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import { Row, SelectInput, Text } from '~/ui/index.js';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import TableRowsLoader from '~/ui/components/mui-table/table-loader';
import TablePagination from '~/ui/components/table-pagination/index';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { ApprovePopup, RejectPopup } from '../actions/index';
import { SortDirection } from '~/data/utils/types';
import s from './styles.module.scss';

const options = [
  { value: true, label: 'Only pending' },
  { value: false, label: 'All' },
];

const renderStatus = (status, onReject, onApprove) => {
  switch (status) {
    case 'Approved':
      return (
        <div className={cx(s.approvalsStamp, s.approvalsStampSuccess)}>
          <Text size={Text.size.xs} color={Text.color.white}>
            Approved
          </Text>
        </div>
      );
    case 'Rejected':
      return (
        <div className={cx(s.approvalsStamp, s.approvalsStampError)}>
          <Text size={Text.size.xs} color={Text.color.white}>
            Rejected
          </Text>
        </div>
      );
    default:
      return (
        <Box display="flex" flexWrap="nowrap">
          <IconButton onClick={onApprove} className={s.approvalsButton}>
            <DoneOutlinedIcon />
          </IconButton>
          <IconButton onClick={onReject} className={s.approvalsButton}>
            <ClearOutlinedIcon />
          </IconButton>
        </Box>
      );
  }
};

const ApprovalsContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { page } = useParams<{ page: string }>();

  const approvals = useAppSelector(selectors.approvals.getItems);
  const isApprovalsLoaded = useAppSelector(selectors.approvals.itemsLoaded);
  const totalItems = useAppSelector(selectors.approvals.getTotalItems);
  const reportRejecting = useAppSelector(selectors.approvals.getReportRejecting);
  const reportApproving = useAppSelector(selectors.approvals.getReportApproving);
  const reportApproved = useAppSelector(selectors.approvals.getReportApproving);

  const [sort, setSort] = useState<{ key: string; direction: SortDirection }>({
    key: 'inserted',
    direction: SortDirection.Desc,
  });
  const [perPage, setPerPage] = useState(10);
  const [onlyPending, setOnlyPending] = useState(true);
  const [reportToApprove, setReportToApprove] = useState(null);
  const [reportToReject, setReportToReject] = useState(null);

  const fetchApprovals = (filter) => dispatch(actions.approvals.fetchList(filter));
  const clear = () => dispatch(actions.approvals.clear());
  const approveReport = (requestId) => dispatch(actions.approvals.approveReport(requestId));
  const rejectReport = (requestId, values, formikActions) =>
    dispatch(actions.approvals.rejectReport(requestId, values, formikActions));

  const queryParams = {
    sort: sort.key,
    onlyPending,
    direction: sort.direction,
    limit: perPage,
    offset: Number(page) > 1 ? (Number(page) - 1) * perPage : 0,
  };

  useEffect(() => {
    fetchApprovals(queryParams);
    return () => {
      clear();
    };
  }, [reportRejecting, reportApproving, page, perPage, sort, onlyPending]);

  const onFilter = (isOnlyPending) => {
    setOnlyPending(isOnlyPending);
    onFilterHelper('onlyPending', isOnlyPending);
  };

  const onSort = (key, direction) => {
    setSort({ key, direction });
    onSortingHelper(key, direction);
  };

  const onPagination = (e) => {
    setPerPage(e.target.value);
    onPaginationHelper(e.target);
  };

  const handleApprove = async (reportId) => {
    if (reportApproved || reportApproving) return;
    const res = await approveReport(reportId);
    if (res) {
      setReportToApprove(null);
    }
  };

  const handleReject = async (reportId, values, formikActions) => {
    const res = await rejectReport(reportId, values, formikActions);
    if (res) {
      setReportToReject(null);
    }
  };

  if (isApprovalsLoaded && approvals.size === 0 && Number(page) > 1) {
    return <Redirect to={approvalsPagePath(Math.ceil(totalItems / perPage))} />;
  }
  return (
    <div className={s.approvals}>
      <Row className={s.approvalsRow}>
        <Text size={Text.size.xl} weight={Text.weight.semiMedium} color={Text.color.colorTextDark}>
          Approvals
        </Text>
      </Row>
      <Box>
        <div className={s.approvalsSelect}>
          <SelectInput
            name="sort-clients"
            dataCy="clientSort"
            options={options}
            value={options.filter((option) => option.value === onlyPending)}
            onChange={(e) => onFilter(e.value)}
          />
        </div>
      </Box>
      <Row className={s.approvalsCards}>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="20%" key="clientName">
                    <TableSortLabel
                      active={!!approvals?.size && sort.key === 'clientName'}
                      direction={sort.direction}
                      onClick={() => onSort('clientName', sort.direction === 'desc' ? 'asc' : 'desc')}
                    >
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                        Client name
                      </Text>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width="10%" key="reason">
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      Reason
                    </Text>
                  </TableCell>
                  <TableCell width="30%" key="comment">
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      Comment
                    </Text>
                  </TableCell>
                  <TableCell width="10%" key="inserted">
                    <TableSortLabel
                      active={!!approvals?.size && sort.key === 'inserted'}
                      direction={sort.direction}
                      onClick={() => onSort('inserted', sort.direction === 'desc' ? 'asc' : 'desc')}
                    >
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                        Date
                      </Text>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width="10%" key="status">
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      Status
                    </Text>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isApprovalsLoaded ? (
                  <>
                    {approvals.size ? (
                      approvals.map((approval) => (
                        <TableRow key={approval.get('id')}>
                          <TableCell>
                            <Text size={Text.size.s}>{approval.get('clientName')}</Text>
                          </TableCell>
                          <TableCell>
                            <Text size={Text.size.s}>Frozen</Text>
                          </TableCell>
                          <TableCell
                            className={s.approvalsCardsCol}
                            data-tip={approval.get('message')}
                            data-for="message"
                          >
                            <Text className={s.approvalsCardsComment} size={Text.size.s}>
                              {approval.get('message')}
                              <ReactTooltip id="message" />
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text size={Text.size.s}>{formatDate(approval.get('inserted'))}</Text>
                          </TableCell>
                          <TableCell>
                            {renderStatus(
                              approval.get('status'),
                              () => setReportToReject(approval),
                              () => setReportToApprove(approval),
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <Box marginTop="20px">
                        <Text>No items found</Text>
                      </Box>
                    )}
                  </>
                ) : (
                  <TableRowsLoader rowsNum={perPage} colsNum={5} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {isApprovalsLoaded && !!approvals.size && (
            <div className={s.approvalsPagination}>
              <TablePagination
                page={page || 1}
                totalItems={totalItems}
                handleChangePage={(p) => history.push(approvalsPagePath(p + 1))}
                perPage={perPage}
                onRowsPerPageChange={onPagination}
              />
            </div>
          )}
        </Paper>
      </Row>

      {reportToReject && (
        <RejectPopup
          closeFunction={() => setReportToReject(null)}
          handleReject={handleReject}
          // @ts-expect-error
          reportId={reportToReject.get('id')}
        />
      )}

      {reportToApprove && (
        <ApprovePopup
          closeFunction={() => setReportToApprove(null)}
          request={reportToApprove}
          isLoading={reportApproving}
          // @ts-expect-error
          handleApprove={() => handleApprove(reportToApprove.get('id'))}
        />
      )}
    </div>
  );
};

export default ApprovalsContainer;
