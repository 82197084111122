import * as constants from '~/data/constants/approvals';
import * as authConstants from '~/data/constants/auth';
import LoadingProgress from '~/data/utils/reducers/loading';
import Pagination from '~/data/utils/reducers/pagination';
import { fromJS } from 'immutable';

export const itemsPagination = new Pagination('approvals');
export const listLoadingProgress = new LoadingProgress('approvalsList');
export const approvalProgress = new LoadingProgress('approval');
export const rejectionProgress = new LoadingProgress('approval');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { approvals },
    } = payload;
    newState.mergeIn(['entities'], fromJS(approvals));
  });

const loadItems = (state, action) =>
  state.withMutations((newState) => {
    const { result, limit } = action.payload;
    const { count } = action;
    mergeData(newState, action.payload);
    itemsPagination.set(newState, count, limit, fromJS(result));
    listLoadingProgress.setLoaded(newState);
  });

const initialState = fromJS({
  entities: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadItems(state, action);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.APPROVAL_START:
      return approvalProgress.setLoading(state);
    case constants.APPROVAL_SUCCESS:
      return approvalProgress.setLoaded(state);
    case constants.APPROVAL_FAILED:
      return approvalProgress.setLoadFailed(state);

    case constants.REJECTION_START:
      return rejectionProgress.setLoading(state);
    case constants.REJECTION_SUCCESS:
      return rejectionProgress.setLoaded(state);
    case constants.REJECTION_FAILED:
      return rejectionProgress.setLoadFailed(state);
    case authConstants.SESSION_CLOSE:
    case constants.CLEAR:
      return initialState;
    default:
      return state;
  }
};
