import API from '~/data/api';
import { baseURL } from '~/config';
import reducers from '~/data/reducers';
import { jwtCheck } from '~/data/utils/jwt-middleware';
import * as schema from '~/data/utils/schemas';
import Immutable from 'immutable';
import { normalize } from 'normalizr';
import { applyMiddleware, createStore as reduxCreateStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';

/* eslint-disable */
export let api;
/* eslint-enable */

const createStore = () => {
  const composeEnhancers = composeWithDevTools({
    serialize: {
      immutable: Immutable,
    },
  } as any);

  const thunkExtraArguments = {
    schema,
    normalize,
    api: null,
  };

  const actionTimestamp = () => (next) => (action) =>
    next({
      ...action,
      timestamp: new Date(),
    });

  const store = reduxCreateStore(
    reducers,
    composeEnhancers(
      applyMiddleware(jwtCheck, thunk.withExtraArgument(thunkExtraArguments), actionTimestamp) as any,
    ) as any,
  );

  api = new API(store, { baseURL: baseURL.toString() });

  thunkExtraArguments.api = api;

  // store.dispatch(refreshSession());

  return store;
};

export default createStore;
