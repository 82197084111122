import { AnyPropsType } from '~/AnyPropsType';
import React from 'react';
import { Button } from '@mui/material';
import { Text } from '~/ui/index.js';
import Modal from '~/ui/components/modal/index';

import s from './styles.module.scss';

const WarningPopup = ({ closeFunction, title, warning }: AnyPropsType) => (
  <Modal open modalTitle={<Text weight={Text.weight.medium}>{title}</Text>} contentText={warning}>
    <div className={s.warningForm}>
      <div className={s.warningFormButtons}>
        <Button onClick={closeFunction} variant="outlined">
          Close
        </Button>
      </div>
    </div>
  </Modal>
);

// WarningPopup.propTypes = {
//   closeFunction: PropTypes.func.isRequired,
//   title: PropTypes.string.isRequired,
//   warning: PropTypes.string.isRequired,
// };

export default WarningPopup;
