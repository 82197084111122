export const baseAuditorPath = '/auditor';

const applyBaseUrl = (url) => `${baseAuditorPath}/${url}`;

export const clientsPath = applyBaseUrl('clients');

export const privacyPath = applyBaseUrl('privacy');
export const taxonomiesPath = applyBaseUrl('taxonomies');

export const clientsPagePath = (page) => `${clientsPath}/page/${page}`;

export const clientPath = (clientId) => `${clientsPath}/${clientId}`;

export const reportsPath = (clientId) => `${clientPath(clientId)}/reports`;

export const reportPath = (clientId, reportId) => `${clientPath(clientId)}/reports/${reportId}`;

export const reportPagePath = (clientId, reportId, page) => `${reportPath(clientId, reportId)}/page/${page}`;

export const reportComparisonsPath = (clientId, reportId, comparisonId) =>
  `${reportPath(clientId, reportId)}/comparisons/${comparisonId}`;

export const reportComparisonsPagePath = (clientId, reportId, comparisonId, page) =>
  `${reportComparisonsPath(clientId, reportId, comparisonId)}/page/${page}`;

export const reportComparisonPath = (clientId, reportId, fileId) =>
  `${reportPath(clientId, reportId)}/comparison/${fileId}`;

export const reportsPagePath = (clientId, page) => `${reportsPath(clientId)}/page/${page}`;

export const clientEditPath = (clientId) => `${clientPath(clientId)}/edit`;

export const clientUploadPath = (clientId) => `${clientPath(clientId)}/upload`;

export const clientComparisonsPath = (clientId) => `${clientPath(clientId)}/comparisons`;

export const clientComparisonsPagePath = (clientId, page) => `${clientComparisonsPath(clientId)}/page/${page}`;

export const clientDashboardPath = (clientId, type?) => `${clientPath(clientId)}/dashboard/${type}`;

export const entitiesPath = (clientId, reportId) => `${reportsPath(clientId)}/${reportId}/entities`;

export const dropzonesPath = (clientId, reportId, entityId) =>
  `${entitiesPath(clientId, reportId)}/${entityId}/dropzones`;

// manage clients
export const manageClientsPath = applyBaseUrl('manage-clients');

export const manageClientsAddPath = `${manageClientsPath}/add`;

export const manageClientsAddConnectedClientPath = (clientId) =>
  `${manageClientsPath}/${clientId}/create-connected-client`;

export const manageClientsPagePath = (page) => `${manageClientsPath}/page/${page}`;

export const manageClientPath = (clientId) => `${manageClientsPath}/${clientId}`;

export const manageClientPagePath = (clientId, page) => `${manageClientPath(clientId)}/page/${page}`;

export const manageClientConnectUserPath = (clientId) => `${manageClientsPath}/${clientId}/connect-user`;

export const manageClientConnectUserPagePath = (clientId, page) =>
  `${manageClientConnectUserPath(clientId)}/page/${page}`;

// users
export const usersPath = applyBaseUrl('users');

export const usersPagePath = (page) => `${usersPath}/page/${page}`;

export const userPath = (userId) => `${usersPath}/${userId}`;

export const userConnectedClientsPath = (userId) => `${userPath(userId)}/connected-clients`;
export const userConnectedClientsPagePath = (userId, page) => `${userConnectedClientsPath(userId)}/page/${page}`;

export const userAddPath = `${usersPath}/add`;

export const userEditPath = (userId) => `${userPath(userId)}/edit`;

export const userEditConnectedClientsPath = (userId) => `${userEditPath(userId)}/connected-clients`;
export const userEditConnectedClientsPagePath = (userId, page) =>
  `${userEditConnectedClientsPath(userId)}/page/${page}`;

export const userEditAvailableClientsPath = (userId) => `${userEditPath(userId)}/available-clients`;
export const userEditAvailableClientsPagePath = (userId, page) =>
  `${userEditAvailableClientsPath(userId)}/page/${page}`;

export const userAvailableClientsPath = (userId) => `${userPath(userId)}/available-clients`;
export const userAvailableClientsPagePath = (userId, page) => `${userAvailableClientsPath(userId)}/page/${page}`;
// Approvals

export const approvalsPath = applyBaseUrl('approvals');

export const approvalsPagePath = (page) => `${approvalsPath}/page/${page}`;
export const adminPath = applyBaseUrl('admin');
export const ebaImportsPath = `${adminPath}/eba-imports`;
export const ebaImportsPagePath = (page) => `${ebaImportsPath}/page/${page}`;
export const particlesPath = `${adminPath}/particles`;
export const particlesPagePath = (page) => `${particlesPath}/page/${page}`;
export const clientUsageReportPath = `${adminPath}/client-usage-report`;
