import { approvalsPagePath, approvalsPath } from '~/app/constants/url/auditor';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Approvals } from './approvals/index';

export const ApprovalsRouter = () => (
  <Switch>
    <Route path={approvalsPagePath(':page([1-9]\\d*)')} component={Approvals} />
    <Redirect from={approvalsPath} to={approvalsPagePath(1)} />
  </Switch>
);
