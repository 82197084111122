import React from 'react';

class AnchorInternal extends React.Component<any> {
  // static propTypes = {
  //   register: PropTypes.func.isRequired,
  //   unregister: PropTypes.func.isRequired,
  //   update: PropTypes.func.isRequired,
  //   path: PropTypes.string.isRequired,
  //   link: PropTypes.string.isRequired,
  //   component: PropTypes.node.isRequired,
  //   // eslint-disable-next-line
  //   wrapperStyle: PropTypes.object,
  // };

  static defaultProps = {
    wrapperStyle: null,
  };

  componentDidMount() {
    const { link, path, wrapperStyle, component, register } = this.props;
    register({
      link,
      path,
      component,
      wrapperStyle,
    });
  }

  componentDidUpdate(prevProps) {
    const { link, wrapperStyle, component, update } = this.props;
    if (component !== prevProps.component || link !== prevProps.link) {
      update({
        link,
        path: prevProps.path,
        component,
        wrapperStyle,
      });
    }
  }

  componentWillUnmount() {
    const { link, path, wrapperStyle, component, unregister } = this.props;
    unregister({
      link,
      path,
      component,
      wrapperStyle,
    });
  }

  render() {
    return null;
  }
}

const BreadcrumbsAnchor = ({ context, ...props }) => {
  const { Consumer } = context;
  return (
    <Consumer>
      {(value) => (
        <AnchorInternal register={value.register} unregister={value.unregister} update={value.update} {...props} />
      )}
    </Consumer>
  );
};

// BreadcrumbsAnchor.propTypes = {
//   context: PropTypes.shape({
//     Provider: PropTypes.object.isRequired,
//     Consumer: PropTypes.object.isRequired,
//   }).isRequired,
// };

export default BreadcrumbsAnchor;
