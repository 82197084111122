import FormField from '~/app/components/core/form/field';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { Text } from '~/ui/index.js';
import { Button, TextField } from '@mui/material';

import s from './styles.module.scss';
import Modal from '~/ui/components/modal/index';
import { ReportRejectSchema } from '~/data/index.js';

interface RejectPopupProps {
  closeFunction: () => void;
  reportId: string;
  handleReject: (reportId: string, values: any, formikActions: FormikHelpers<any>) => void;
}

const RejectPopup = ({ closeFunction, handleReject, reportId }: RejectPopupProps) => {
  const onSubmit = (values, formikActions) => {
    handleReject(reportId, values, formikActions);
  };
  return (
    <Modal
      open
      maxWidth="xs"
      onClose={closeFunction}
      modalTitle={<Text weight={Text.weight.medium}>Reject report</Text>}
      contentText="Are you sure you want to reject this report?"
    >
      <Formik
        onSubmit={onSubmit}
        validationSchema={ReportRejectSchema}
        initialValues={{
          rejectionMessage: '',
        }}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form className={s.popupForm}>
            <Field
              className={s.popupField}
              inputComponent={TextField}
              component={FormField}
              id="rejectionMessage"
              name="rejectionMessage"
              placeholder="Rejection message"
              label="Rejection message"
              fullWidth
            />
            <div className={s.popupFormButtons}>
              <Button onClick={closeFunction} variant="outlined">
                Close
              </Button>
              <Button type="submit" variant="contained" disabled={isSubmitting}>
                Reject
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default RejectPopup;
