import { AnyPropsType } from '~/AnyPropsType';
import { clientsPagePath, reportsPath } from '~/app/constants/url/auditor';
import React from 'react';
import { Row, SelectInput, Text } from '~/ui/index.js';
import { formatDate } from '~/app/utils/format-data';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import TablePagination from '~/ui/components/table-pagination/index';
import TableRowsLoader from '~/ui/components/mui-table/table-loader';
import Search from '@mui/icons-material/Search';

import s from './styles.module.scss';

const options = [
  { value: { key: 'lastUpdated', direction: 'DESC' }, label: 'Recent update' },
  { value: { key: 'name', direction: 'ASC' }, label: 'Alphanumeric' },
];

const Clients = ({
  clients,
  onSearch,
  search,
  onSort,
  sort,
  page,
  perPage,
  totalItems,
  onPagination,
  isClientsLoaded,
}: AnyPropsType) => {
  const theme = useTheme();
  const history = useHistory();

  const columns = [
    {
      id: 'clientName',
      field: 'clientName',
      label: 'Client name',
      width: 750,
    },
    { id: 'vatNumber', field: 'vatNumber', label: 'VAT Number', width: 100 },
    {
      id: 'lastUpdated',
      field: 'lastUpdated',
      label: 'Last updated',
      width: 100,
    },
  ];

  const rows: any[] =
    clients
      ?.map((client) => ({
        clientName: client.get('name'),
        vatNumber: client.get('vatNumber'),
        lastUpdated: formatDate(client.get('lastUpdated')),
        id: client.get('id'),
      }))
      ?.toJS() || [];

  return (
    <>
      <div className={s.clients}>
        <Row className={s.clientsRow}>
          <Text size={Text.size.xl} weight={Text.weight.semiMedium} color={Text.color.colorTextDark}>
            Clients
          </Text>
        </Row>
        <Row className={s.clientsRow}>
          <Box width={345}>
            <TextField
              placeholder="Search client"
              InputProps={{
                startAdornment: <Search htmlColor={theme.palette.secondary.light} />,
              }}
              onChange={({ target: { value } }) => onSearch(value)}
              value={search}
              size="small"
              color="primary"
              fullWidth
            />
          </Box>

          <div className={s.clientsSelect}>
            <SelectInput
              name="sort-clients"
              dataCy="clientSort"
              options={options}
              value={options.filter((option) => option.value.key === sort)}
              onChange={(e) => onSort(e.value)}
            />
          </div>
        </Row>

        <Row className={s.clientsTableRow}>
          <Paper>
            <TableContainer>
              <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id}>
                        <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                          {column.label}
                        </Text>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isClientsLoaded ? (
                    rows.map((row) => (
                      <TableRow hover tabIndex={-1} key={row.code} component={Link} to={reportsPath(row.id)}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id}>
                              <Text
                                size={Text.size.s}
                                font={Text.color.colorTextMedium}
                                weight={Text.weight.semiMedium}
                              >
                                {value}
                              </Text>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                  ) : (
                    <TableRowsLoader rowsNum={perPage} colsNum={3} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={s.paginationWrap}>
              <TablePagination
                totalItems={totalItems}
                perPage={perPage}
                page={page}
                handleChangePage={(p) => {
                  history.push(clientsPagePath(p + 1));
                }}
                onRowsPerPageChange={onPagination}
              />
            </div>
          </Paper>
        </Row>
      </div>
    </>
  );
};

export default Clients;
