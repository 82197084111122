import * as apiConstants from '~/data/constants/api';
import * as rowConstants from '~/data/constants/comparisonrows';
import * as constants from '~/data/constants/comparisons';
import { saveBlob } from '~/data/utils/helpers';

export const fetchComparisons =
  (clientId, filter) =>
  async (dispatch, _, { api, schema, normalize }) => {
    const newFilter = filter;
    if (newFilter) {
      Object.keys(newFilter).forEach((key) => {
        if (
          newFilter[key] === undefined ||
          newFilter[key] === false ||
          newFilter[key] === '' ||
          newFilter[key] === null
        ) {
          delete newFilter[key];
        }
      });
    }

    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const res = await api.get(`clients/${clientId}/comparisons`, {
        params: newFilter,
      });

      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        count: res.data.count,
        payload: normalize(res.data.items, [schema.comparison]),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
    }
  };

export const fetchReportComparisons =
  (clientId, reportId, filter) =>
  async (dispatch, _, { api, schema, normalize }) => {
    const newFilter = filter;
    if (newFilter) {
      Object.keys(newFilter).forEach((key) => {
        if (
          newFilter[key] === undefined ||
          newFilter[key] === false ||
          newFilter[key] === '' ||
          newFilter[key] === null
        ) {
          delete newFilter[key];
        }
      });
    }

    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const res = await api.get(`clients/${clientId}/reports/${reportId}/comparisons`, {
        params: newFilter,
      });

      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        count: res.data.count,
        payload: normalize(res.data.items, [schema.comparison]),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
    }
  };

export const fetchClientComparisons =
  (clientId, filter) =>
  async (dispatch, _, { api, schema, normalize }) => {
    const newFilter = filter;
    if (newFilter) {
      Object.keys(newFilter).forEach((key) => {
        if (
          newFilter[key] === undefined ||
          newFilter[key] === false ||
          newFilter[key] === '' ||
          newFilter[key] === null
        ) {
          delete newFilter[key];
        }
      });
    }

    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const res = await api.get(`clients/${clientId}/comparisons`, {
        params: newFilter,
      });

      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        count: res.data.count,
        payload: normalize(res.data.items, [schema.comparison]),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
    }
  };

export const downloadComparisonExcel =
  (comparisonId, name) =>
  async (dispatch, getState, { api }) => {
    dispatch({ type: constants.DOWNLOAD_START });

    try {
      const res = await api.get(`/comparisons/${comparisonId}/visual-excel`, {
        responseType: 'blob',
      });
      if (res.status !== 200) throw new Error(res.statusText);

      saveBlob(res.data, name);

      dispatch({
        type: constants.DOWNLOAD_SUCCESS,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.DOWNLOAD_FAILED });
      dispatch({
        type: apiConstants.SERVER_ERROR,
        error: { title: 'Download failed, Contact your system administrator if this keeps happening.' },
      });

      throw e;
    }
  };

export const createComparison =
  (fileId) =>
  async (dispatch, getState, { api }) => {
    dispatch({ type: constants.CREATE_START });

    try {
      const res = await api.post(`/comparisons`, { fileId });
      if (res.status !== 200) throw new Error(res.statusText);

      dispatch({
        type: constants.CREATE_SUCCESS,
      });

      return res;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.CREATE_FAILED });

      throw e;
    }
  };

export const fetchComparison =
  (comparisonId) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LOAD_START });
    try {
      const res = await api.get(`/comparisons/${comparisonId}`);

      dispatch({
        type: constants.LOAD_SUCCESS,
        payload: normalize(res.data, schema.comparison),
      });
    } catch (e) {
      dispatch({ type: constants.LOAD_FAILED });
    }
  };

export const fetchComparisonRows =
  (comparisonId, filter) =>
  async (dispatch, _, { api, schema, normalize }) => {
    const newFilter = filter;
    if (newFilter) {
      Object.keys(newFilter).forEach((key) => {
        if (
          newFilter[key] === undefined ||
          newFilter[key] === false ||
          newFilter[key] === '' ||
          newFilter[key] === null ||
          (Number.isNaN(Number(newFilter[key])) && newFilter[key]?.length < 3)
        ) {
          delete newFilter[key];
        }
      });
    }

    dispatch({ type: rowConstants.LIST_LOAD_START });
    try {
      const res = await api.get(`comparisons/${comparisonId}/rows`, {
        params: newFilter,
      });
      const { count, totalCount, items } = res.data;
      dispatch({
        type: rowConstants.LIST_LOAD_SUCCESS,
        count,
        totalCount,
        payload: normalize(items, [schema.comparisonrow]),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: rowConstants.LIST_LOAD_FAILED });
    }
  };

export const clear = () => ({ type: constants.CLEAR });

export const clearSingle = () => ({ type: constants.CLEAR_SINGLE });

export const clearRows = () => ({ type: rowConstants.CLEAR });
