import { AnyPropsType } from '~/AnyPropsType';
import createBreadcrumbs from '~/app/utils/breadcrumbs';
import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '~/ui/index.js';

import s from './styles.module.scss';

const { Breadcrumbs: BreadcrumbsComponent, Provider, Anchor } = createBreadcrumbs();

type containerProps = {
  className?: string;
  children?: any;
};

const Container = ({ className, children }: containerProps) => (
  <div className={cx(s.breadcrumbs, className)}>{children}</div>
);

Container.defaultProps = {
  className: null,
  children: null,
};

type itemProps = {
  item: {
    link: string;
    content: string;
    wrapperStyle?: any;
    component?: any;
  };
};

const Item = ({ item }: itemProps) => (
  <Link to={item.link} className={s.breadcrumbsItem} style={item.wrapperStyle}>
    {item.component}
  </Link>
);

const Divider = () => <Icon icon={Icon.icons.chevronRight} className={s.breadcrumbsIcon} />;

const Breadcrumbs = ({ ...props }: AnyPropsType) => (
  <BreadcrumbsComponent
    {...props}
    components={{
      Item,
      Divider,
      Container,
    }}
  />
);

Breadcrumbs.Provider = Provider;

Breadcrumbs.Anchor = Anchor;

export default Breadcrumbs;
