import Breadcrumbs from '~/app/components/core/breadcrumbs/index';
import {
  clientDashboardPath,
  clientsPath,
  reportPath,
  reportsPagePath,
  reportsPath,
} from '~/app/constants/url/auditor';
import { actions, selectors, useDispatch, useSelector } from '~/data/index.js';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { Dashboard } from './dashboard/index';
import { ReportRouter } from './report/index';
import { Reports } from './reports/index';

const ClientReportsRouter = () => {
  const dispatch = useDispatch();
  const { clientId } = useParams<{ clientId: string }>();

  const client = useSelector((state) => selectors.clients.getItem(state, clientId));
  const isClientLoaded = useSelector(selectors.clients.itemLoaded);

  const fetchClient = () => dispatch(actions.clients.fetchClient(clientId));
  const fetchClientScopes = () => dispatch(actions.scopes.fetchClientScopes(clientId, undefined));
  const clearSingle = () => dispatch(actions.clients.clearSingle());
  const clear = () => dispatch(actions.scopes.clear());

  useEffect(() => {
    if (!isClientLoaded) {
      fetchClient();
      fetchClientScopes();
    }
    return () => {
      clear();
      clearSingle();
    };
  }, []);

  if (isClientLoaded && !client) {
    return <Redirect to={clientsPath} />;
  }
  return (
    <>
      <Breadcrumbs.Anchor
        path={reportsPath(':clientId')}
        link={reportsPath(clientId)}
        component={<span title="Client">{isClientLoaded ? client.get('name') : ''}</span>}
      />
      <Switch>
        <Route path={reportsPagePath(':clientId', ':page([1-9]\\d*)')} component={Reports} />
        <Route path={reportPath(':clientId', ':reportId')} component={ReportRouter} />
        <Route exact path={clientDashboardPath(':clientId', ':type')} component={Dashboard} />
        <Redirect from={reportsPath(clientId)} to={reportsPagePath(clientId, 1)} />
      </Switch>
    </>
  );
};

export default ClientReportsRouter;
