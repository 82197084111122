import React, { ReactElement, useEffect, useState } from 'react';
import Button from '@mui/lab/LoadingButton';
import s from './styles.module.scss';
import { Box, TextField } from '@mui/material';
import { Text } from '~/ui/components/text/index';
import { SelectInput } from '~/ui/components/form/select/index';
import { Field, Form, Formik } from 'formik';
import { ReportSchema, createDropzoneUrl } from '~/data/index.js';
import { RegionFileType, ReportInfoInitialValues, ScopeFileName } from '~/data/constants/app';
import FormField from '../form/field';
import { scopeLabels } from '~/app/constants/app/options';
import { MaterialDatePicker } from '~/ui/components/mui-datepicker/index';
import { useHistory, useParams } from 'react-router-dom';
import { reportsPath } from '~/app/constants/url/auditor';
import AnimateHeight from 'react-animate-height';
import Modal from '~/ui/components/modal/index';

const UploadFilePopUp = ({ handleClose, client, createReport, checkReport, scopes, logout }): ReactElement => {
  const history = useHistory();
  const { clientId = '' } = useParams<{ clientId: string }>();

  const [isFrozenValue, setIsFrozenValue] = useState(false);
  const [dropzoneUrl, setDropzoneUrl] = useState(null);
  const [iFrameHeight, setIFrameHeight] = useState(500);

  const scopeOptions = scopes?.size
    ? scopes.toJS().map((scopeType) => ({ value: scopeType.name, label: scopeLabels[scopeType.name] }))
    : [];

  const handleSubmit = async (values, formikActions) => {
    const { data } = await createReport({ ...values, clientId }, formikActions);

    if (data) {
      setDropzoneUrl(createDropzoneUrl(data.dropzoneData));
    }
  };

  const handleIframeTask = ({ data }) => {
    if (data) {
      if (data.code === '401' || data.code === '403') {
        logout();
      }
      if (data.sent) {
        history.push(reportsPath(clientId));
      }
      if (data.height) {
        setIFrameHeight(data.height);
      }
    }
  };

  const checkIsFrozen = async (values, formikActions) => {
    const { isFrozen } = await checkReport({ ...values, clientId }, formikActions);
    if (isFrozen) {
      setIsFrozenValue(true);
      setDropzoneUrl(null);
    } else {
      setIsFrozenValue(false);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleIframeTask);
  }, []);

  return (
    <Modal
      open
      closeIcon
      onClose={handleClose}
      maxWidth={dropzoneUrl ? 'md' : 'sm'}
      className={s.vatDialog}
      modalTitle={
        <Text weight={Text.weight.semiMedium}> {`Upload ${RegionFileType[client?.get('region')]} file`}</Text>
      }
      contentText={
        !dropzoneUrl && (
          <Text size={Text.size.xs} color={Text.color.orange}>
            Fill in the information to upload a new XBRL file
          </Text>
        )
      }
    >
      <Formik
        onSubmit={handleSubmit}
        validationSchema={ReportSchema(isFrozenValue, client?.get('region'))}
        initialValues={{
          periodEnd: null,
          ...ReportInfoInitialValues[client?.get('region')],
          approvalRequestMessage: '',
        }}
        enableReinitialize
      >
        {({ values, ...formikActions }) => {
          useEffect(() => {
            if ((values.infoParticles?.scope || values.infoSa?.scope) && values.periodEnd) {
              checkIsFrozen(values, formikActions);
            }
          }, [values.infoParticles?.scope, values.infoSa?.scope, values.periodEnd]);

          return (
            <Form>
              {dropzoneUrl ? (
                <>
                  <Text size={Text.size.xxs} height={Text.height.xl}>
                    {`You can only upload ${RegionFileType[client?.get('region')]} files. The max file size is 10mb.`}
                  </Text>
                  <AnimateHeight height={dropzoneUrl ? iFrameHeight : 0}>
                    <iframe
                      frameBorder="0"
                      src={dropzoneUrl}
                      className={s.clientDropzone}
                      title="Dropzone"
                      id="dropzone"
                      height={iFrameHeight}
                      scrolling="no"
                      style={{ borderStyle: 'none', width: '100%' }}
                    />
                  </AnimateHeight>
                </>
              ) : (
                <>
                  <Box width={300} marginRight="30px">
                    <Field
                      inputComponent={SelectInput}
                      component={FormField}
                      options={scopeOptions}
                      isDisabled={scopeOptions.length === 0}
                      id={ScopeFileName[client?.get('region')]}
                      name={ScopeFileName[client?.get('region')]}
                      placeholder={scopeOptions.length === 0 ? 'No Scopes' : 'Select scope'}
                      className={s.clientField}
                      onChangeAdapter={(value) => value.value}
                      valueAdapter={(value) => scopeOptions.find((x) => x.value === value)}
                    />
                  </Box>
                  <Box>
                    <Field
                      inputComponent={MaterialDatePicker}
                      component={FormField}
                      id="periodEnd"
                      name="periodEnd"
                      label="Period end"
                      valueAdapter={(v) => v}
                      onChangeAdapter={(v) => new Date(v.getTime() - v.getTimezoneOffset() * 60000)}
                      error={formikActions.errors.periodEnd}
                    />
                  </Box>

                  <Box marginBottom={2}>
                    <AnimateHeight height={isFrozenValue ? 'auto' : 0}>
                      {isFrozenValue && (
                        <Field
                          name="approvalRequestMessage"
                          component={FormField}
                          inputComponent={TextField}
                          multiline
                          fullWidth
                          label="Approval request message"
                          color="primary"
                          minRows={3}
                        />
                      )}
                    </AnimateHeight>
                  </Box>
                  <Box className={s.buttonsWrap}>
                    <Button variant="outlined" color="primary" onClick={handleClose}>
                      Cancel
                    </Button>

                    <Button variant="contained" color="primary" type="submit" loading={formikActions.isSubmitting}>
                      Next step
                    </Button>
                  </Box>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default UploadFilePopUp;
