import { RootStateOrAny } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

/* eslint-disable no-shadow */
export enum Region {
  Belgium = 'Belgium',
  SouthAfrica = 'SouthAfrica',
}

export enum ReportStatus {
  Created = 'Created',
  ReceivingFiles = 'ReceivingFiles',
  Uploaded = 'Uploaded',
  ValidationCombinationsCreated = 'ValidationCombinationsCreated',
  Done = 'Done',
  ScopeFailed = 'ScopeFailed',
  VatFailed = 'VatFailed',
  PeriodFailed = 'PeriodFailed',
  ExternalError = 'ExternalError',
  InvalidInput = 'InvalidInput',
  UnknownError = 'UnknownError',
  NotAllFilesReceived = 'NotAllFilesReceived',
  Validated = 'Validated',
  Deleting = 'Deleting',
  PendingApproval = 'PendingApproval',
  Rejected = 'Rejected',
  Deleted = 'Deleted',
  TimedOut = 'TimedOut',
  DoneWithErrors = 'DoneWithErrors',
}

export enum ComparisonStatus {
  Created = 'Created',
  Uploading = 'Uploading',
  Uploaded = 'Uploaded',
  BftSaved = 'BftSaved',
  Processing = 'Processing',
  Finished = 'Finished',
  IncompatibleFiles = 'IncompatibleFiles',
  Failed = 'Failed',
}

export type TypedDispatch = ThunkDispatch<RootStateOrAny, any, AnyAction>;

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}
