import * as apiConstants from '~/data/constants/api';
import * as constants from '~/data/constants/reports';
import { saveBlob } from '~/data/utils/helpers';

export const fetchReports =
  (clientId, filter) =>
  async (dispatch, _, { api, schema, normalize }) => {
    const newFilter = filter;
    if (newFilter) {
      Object.keys(newFilter).forEach((key) => {
        if (
          newFilter[key] === undefined ||
          newFilter[key] === false ||
          newFilter[key] === '' ||
          newFilter[key] === null
        ) {
          delete newFilter[key];
        }
      });
    }

    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const res = await api.get(`clients/${clientId}/reports`, {
        params: newFilter,
      });

      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        count: res.data.count,
        payload: normalize(res.data.items, [schema.report]),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
    }
  };

export const checkReport =
  (values, actions) =>
  async (dispatch, getState, { api }) => {
    dispatch({ type: constants.CHECK_START });
    actions.setSubmitting(true);
    try {
      const res = await api.get(`/clients/${values.clientId}/reports/periods/frozen`, {
        params: {
          periodEnd: values.periodEnd.toISOString(),
          scope: values.infoParticles?.scope || values.infoSa?.scope,
        },
      });
      if (res.status !== 200) throw new Error(res.statusText);
      actions.setSubmitting(false);
      actions.setErrors({});

      dispatch({
        type: constants.CHECK_SUCCESS,
      });

      return res.data;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.CHECK_FAILED });
      actions.setSubmitting(false);

      throw e;
    }
  };

export const createReport =
  (values, actions) =>
  async (dispatch, getState, { api }) => {
    dispatch({ type: constants.CREATE_START });
    actions.setSubmitting(true);

    try {
      const res = await api.post(`/clients/${values.clientId}/reports`, values);
      if (res.status !== 200) throw new Error(res.statusText);
      actions.setSubmitting(false);
      actions.setStatus('success');

      dispatch({
        type: constants.CREATE_SUCCESS,
      });

      return res;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.CREATE_FAILED });
      actions.setSubmitting(false);

      throw e;
    }
  };

export const downloadReportExcel =
  (clientId, reportId, fileId, name) =>
  async (dispatch, getState, { api }) => {
    dispatch({ type: constants.DOWNLOAD_START });

    try {
      const res = await api.get(`/clients/${clientId}/reports/${reportId}/files/${fileId}/visual-excel`, {
        responseType: 'blob',
      });

      if (res.status !== 200) throw new Error(res.statusText);

      saveBlob(res.data, name);

      dispatch({
        type: constants.DOWNLOAD_SUCCESS,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.DOWNLOAD_FAILED });
      dispatch({
        type: apiConstants.SERVER_ERROR,
        error: { title: 'Download failed, Contact your system administrator if this keeps happening.' },
      });
      throw e;
    }
  };

export const fetchReport =
  (clientId, reportId) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LOAD_START });
    try {
      const res = await api.get(`/clients/${clientId}/reports/${reportId}`);

      dispatch({
        type: constants.LOAD_SUCCESS,
        payload: normalize(res.data, schema.report),
      });
    } catch (e) {
      dispatch({ type: constants.LOAD_FAILED });
    }
  };

export const deleteReport =
  (clientId, reportId) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.DELETE_START, id: reportId });
    try {
      await api.delete(`/clients/${clientId}/reports/${reportId}`);

      dispatch({
        type: constants.DELETE_SUCCESS,
        id: reportId,
      });
      return true;
    } catch (e) {
      dispatch({ type: constants.DELETE_FAILED });
      return false;
    }
  };

export const clear = () => ({ type: constants.CLEAR });

export const clearSingle = () => ({ type: constants.CLEAR_SINGLE });
