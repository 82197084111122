import React from 'react';
import { AnyPropsType } from '~/AnyPropsType';
import { SelectInput, Text } from '~/ui/index.js';
import { Box, Button, TextField } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { ClientCreationSchema } from '~/data/index.js';
import { Region } from '~/data/utils/types';
import FormField from '~/app/components/core/form/field';

import s from './styles.module.scss';
import AnimateHeight from 'react-animate-height';
import Modal from '~/ui/components/modal/index';

const AddClientPopup = ({ closeFunction, addClient, user, isSuperAdmin }: AnyPropsType) => {
  const regionOptions = Object.values(Region).map((x) => ({
    value: x,
    label: x.split(/(?=[A-Z])/).join(' '),
  }));

  const handleAddClient = async (values, formikActions) => {
    const res = await addClient(values, formikActions);
    if (res) {
      closeFunction();
    }
  };

  return (
    <Modal open closeIcon onClose={closeFunction} modalTitle={<Text weight={Text.weight.medium}>Add client</Text>}>
      <Formik
        onSubmit={handleAddClient}
        validationSchema={ClientCreationSchema}
        initialValues={{
          adminRegion: user && user.adminRegion,
          region: undefined,
        }}
        enableReinitialize
      >
        {({ values, isSubmitting, errors }) => (
          <Form>
            {isSuperAdmin && (
              <Field
                inputComponent={SelectInput}
                options={regionOptions}
                component={FormField}
                id="region"
                name="region"
                placeholder="Region"
                label="Region"
                onChangeAdapter={(value) => value.value}
                onBlurAdapter={(value) => value}
                valueAdapter={(value) => regionOptions.find((x) => x.value === value)}
                className={s.formField}
              />
            )}
            <AnimateHeight height={values.region === Region.Belgium ? 'auto' : 0}>
              {values.region === Region.Belgium && (
                <Field
                  inputComponent={TextField}
                  component={FormField}
                  id="iPowerClientCode"
                  name="iPowerClientCode"
                  placeholder="iPower Client Code"
                  label="iPower Client Code"
                  className={s.formField}
                  fullWidth
                />
              )}
            </AnimateHeight>
            <AnimateHeight height={values.region === Region.SouthAfrica ? 'auto' : 0}>
              {values.region === Region.SouthAfrica && (
                <>
                  <Field
                    inputComponent={TextField}
                    component={FormField}
                    id="name"
                    name="name"
                    placeholder="Client name"
                    label="Client name"
                    className={s.formField}
                    fullWidth
                  />
                  <Field
                    inputComponent={TextField}
                    component={FormField}
                    id="vatNumber"
                    name="vatNumber"
                    placeholder="Client VAT number"
                    label="Client VAT number"
                    className={s.formField}
                    fullWidth
                  />
                </>
              )}
            </AnimateHeight>

            {errors.message && (
              <Text size={Text.size.xxs} color={Text.color.red}>
                {errors.message}
              </Text>
            )}

            <Box className={s.formButtonsWrap}>
              <Button variant="outlined" data-cy="clientEditCancel" onClick={closeFunction}>
                Cancel
              </Button>

              <Button variant="contained" data-cy="saveClient" type="submit" disabled={isSubmitting}>
                Add client
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddClientPopup;
