import { Box, Tab, Tabs } from '@mui/material';
import * as pbi from 'powerbi-client';
import Breadcrumbs from '~/app/components/core/breadcrumbs/index';
import Loader from '~/app/components/core/preloader';
import { scopeLabels } from '~/app/constants/app/options';
import { clientDashboardPath } from '~/app/constants/url/auditor';
import { actions, selectors } from '~/data/index.js';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { DownloadButton, Row, Text } from '~/ui/index.js';

import { useAppDispatch, useAppSelector } from '~/data/utils/hooks';

import WarningPopup from './warning-popup';

import s from './styles.module.scss';

const ClientUploadContainer = () => {
  const dispatch = useAppDispatch();
  const { clientId, type } = useParams<{ clientId: string; type: string }>();

  let pollingHandle: any;
  let embedContainer: HTMLDivElement;
  const powerBi: pbi.service.Service = new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory,
  );

  const client = useAppSelector((state) => selectors.clients.getItem(state, clientId));
  const isClientLoaded = useAppSelector(selectors.clients.itemLoaded);
  const isDashboardLoaded = useAppSelector(selectors.clients.dashboardLoaded);
  const dashboard = useAppSelector(selectors.clients.getDashboard);
  const percentComplete: number = useAppSelector(selectors.clients.getPercentComplete);
  const isFullDownloadingProcessFailed = useAppSelector(selectors.clients.getFullDownloadingProcessFailed);
  const scopes = useAppSelector(selectors.scopes.getItems);
  const isScopesLoaded = useAppSelector(selectors.scopes.itemsLoaded);

  const [downloadingStatus, setDownloadingStatus] = useState(null);
  const [tabsValue, setTabsValue] = useState(0);
  const [warningActive, setWarningActive] = useState(false);

  const fetchDashboardUrl = () => dispatch(actions.clients.fetchDashboardUrl(clientId, type));
  const startDownloadRequest = () => dispatch(actions.clients.startDownloadRequest(clientId, type));
  const downloadReport = (fileName) => dispatch(actions.clients.downloadReport(clientId, type, fileName));
  const pollDownloadStatus = () => dispatch(actions.clients.pollDownloadStatus(clientId, type));
  const clearDownloadingProgress = () => dispatch(actions.clients.clearDownloadingProgress());

  useEffect(() => {
    if (dashboard) {
      const { id, embedToken, embedUrl, isAllDataLoaded } = dashboard;
      const config = {
        accessToken: embedToken,
        embedUrl,
        id,
        tokenType: 1,
      };
      powerBi.embed(embedContainer, config);

      if (!isAllDataLoaded) {
        setWarningActive(!warningActive);
      }
    }
  }, [dashboard]);

  useEffect(() => {
    if (isFullDownloadingProcessFailed) {
      if (pollingHandle) {
        clearInterval(pollingHandle);
        pollingHandle = 0;
      }
      clearDownloadingProgress();
    }
  }, [isFullDownloadingProcessFailed]);

  useEffect(() => {
    fetchDashboardUrl();
  }, [type]);

  const onDownload = async () => {
    if (await startDownloadRequest()) {
      setDownloadingStatus('started');
      pollDownloadStatus();
    } else {
      setDownloadingStatus('finished');
    }
  };

  useEffect(() => {
    if (downloadingStatus === 'started') {
      pollingHandle = setInterval(() => {
        const fileName = `${client.get('name')}(${type})`;
        if (percentComplete < 100) {
          pollDownloadStatus();
        } else {
          if (pollingHandle) {
            clearInterval(pollingHandle);
            pollingHandle = 0;
          }
          const download = async () => {
            const result = await downloadReport(fileName);
            if (result) {
              setDownloadingStatus('finished');
            }
          };
          download();
        }
      }, 4000);
    }
    return () => {
      if (pollingHandle) {
        clearInterval(pollingHandle);
      }
    };
  }, [downloadingStatus, percentComplete]);

  const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabsValue(newValue);
  };

  const dashboardOptions = isScopesLoaded
    ? scopes
        .toJS()
        .filter((scopeType) => scopeType.active)
        .map((scopeType) => ({ value: scopeType.name, label: scopeLabels[scopeType.name] }))
    : [];

  return (
    <>
      <Breadcrumbs.Anchor
        path={clientDashboardPath(':clientId')}
        link={clientDashboardPath(clientId)}
        component={
          <span title="Client">
            {`${
              scopeLabels[type?.charAt(0).toUpperCase() + type?.slice(1)] ||
              type.charAt(0).toUpperCase() + type.slice(1)
            } dashboard`}
          </span>
        }
      />

      <Box margin="32px 0">
        <Text size={Text.size.xl} weight={Text.weight.semiMedium} color={Text.color.colorTextDark}>{`${
          scopeLabels[type] || type
        } dashboard (${client?.get('name')})`}</Text>
      </Box>
      <Row className={s.tabsRow}>
        <Tabs value={tabsValue} onChange={handleTabsChange} className={s.tabs}>
          {isScopesLoaded &&
            dashboardOptions.map((option, i) => (
              <Tab
                component={Link}
                label={option.label}
                to={clientDashboardPath(clientId, option.value)}
                value={i}
                className={s.tabsTab}
              />
            ))}
        </Tabs>

        <DownloadButton
          onClick={onDownload}
          percentComplete={percentComplete}
          downloadingStatus={downloadingStatus}
          isFullDownloadingProcessFailed={isFullDownloadingProcessFailed}
          className={s.downloadBtn}
          label="Download"
        />
      </Row>

      {isClientLoaded && isDashboardLoaded ? (
        <>
          <div className={s.client}>
            <div className={s.clientFrame}>
              <div
                className="embedContainer"
                // eslint-disable-next-line react/no-unknown-property
                powerbi-type="report"
                style={{ height: '600px', width: '100%' }}
                ref={(div) => {
                  if (div) {
                    embedContainer = div;
                  }
                }}
              />
            </div>
          </div>
          {warningActive && (
            <WarningPopup
              warning="Not all recently uploaded data is available on this dashboard yet. It may take up to 2 hours to be
              updated."
              title="Warning!"
              closeFunction={() => setWarningActive(!warningActive)}
            />
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ClientUploadContainer;
