/* eslint-disable jsx-a11y/anchor-is-valid */
import { AnyPropsType } from '~/AnyPropsType';
import Loader from '~/app/components/core/preloader';
import { scopeLabels } from '~/app/constants/app/options';
import { clientDashboardPath, reportPath, reportsPagePath } from '~/app/constants/url/auditor';
import { formatDate } from '~/app/utils/format-data';
import { RegionFileType } from '~/data/constants/app';
import { ReportStatus } from '~/data/utils/types';
import React, { useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';
import { Row, SelectInput, Text } from '~/ui/index.js';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import TablePagination from '~/ui/components/table-pagination/index';
import TableRowsLoader from '~/ui/components/mui-table/table-loader';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { LoadingButton } from '@mui/lab';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import AddIcon from '@mui/icons-material/Add';

import s from './styles.module.scss';

const getReportStatusStamp = (report) => {
  if (report?.get('status') === 'Done') {
    return <div className={cx(s.statusStamp, s.statusStampSuccess)}>Success</div>;
  }
  if (report?.get('status') === 'DoneWithErrors') {
    return <div className={cx(s.statusStamp, s.statusStampSuccessWithErrors)}>Success (With Errors)</div>;
  }
  if (report?.get('status') === 'PendingApproval') {
    return <div className={cx(s.statusStamp, s.statusStampProcessing)}>Awaiting Approval</div>;
  }
  if (report?.getIn(['statusInfo', 'isFailed'])) {
    return <div className={cx(s.statusStamp, s.statusStampError)}>Error</div>;
  }

  return <div className={cx(s.statusStamp, s.statusStampProcessing)}>Processing</div>;
};

const Reports = ({
  client,
  reports,
  sort,
  onSort,
  onFilter,
  scope,
  page,
  perPage,
  totalItems,
  onPagination,
  isReportsLoaded,
  isClientLoaded,
  toggleReportRemovePopup,
  scopes,
  isScopesLoaded,
  toggleEditVatPopup,
  toggleUploadPopup,
}: AnyPropsType) => {
  const history = useHistory();

  const dashboardOptions = useMemo(
    () =>
      isScopesLoaded
        ? scopes
            .filter((scopeType) => scopeType.get('active'))
            .map((scopeType) => ({ value: scopeType.get('name'), label: scopeLabels[scopeType.get('name')] }))
            .toJS()
        : [],
    [scopes],
  );

  const successStatus = [ReportStatus.Done, ReportStatus.DoneWithErrors];

  return isClientLoaded ? (
    <div className={s.reports}>
      <Row className={s.reportsRow}>
        <Text size={Text.size.xl} weight={Text.weight.semiMedium} color={Text.color.colorTextDark}>
          {client?.get('name')}
        </Text>
      </Row>
      <Row className={s.reportsRowSub}>
        <Text size={Text.size.s} color={Text.color.light}>
          {client?.get('vatNumber')}
        </Text>

        <Button
          endIcon={client.get('vatNumber') ? <BorderColorRoundedIcon /> : <AddIcon />}
          onClick={() => toggleEditVatPopup(client.get('vatNumber') || true)}
        >
          <Text size={Text.size.s} color={Text.color.orange}>
            {client.get('vatNumber') ? `Edit` : 'Add VAT number'}
          </Text>
        </Button>
      </Row>
      <Row className={s.reportsRow}>
        <div className={s.reportsScopeSelect}>
          <SelectInput
            name="sort-reports"
            isDisabled={dashboardOptions.length === 0}
            options={dashboardOptions}
            placeholder="Filter by scope"
            value={dashboardOptions.filter((option) => option.value === scope)}
            onChange={(e) => onFilter(e.value)}
          />
        </div>
        <div className={s.reportsButtons}>
          <Button
            variant="outlined"
            className={s.reportsButtonsUpload}
            data-cy="clientUploadNav"
            onClick={toggleUploadPopup}
            startIcon={<FileUploadOutlinedIcon />}
          >
            {`Upload ${RegionFileType[client?.get('region')]}`}
          </Button>

          <LoadingButton
            variant="contained"
            className={s.reportsButtonsDashboard}
            onClick={() => history.push(clientDashboardPath(client.get('id'), dashboardOptions[0].value))}
            startIcon={<OpenInNewOutlinedIcon />}
            loading={!isScopesLoaded}
            disabled={!dashboardOptions.length}
          >
            {dashboardOptions.length === 0 ? 'No dashboards' : 'Open dashboard'}
          </LoadingButton>
        </div>
      </Row>

      <Row className={s.reportsCards}>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell key="periodEnd" sortDirection={sort}>
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      Period End
                    </Text>
                  </TableCell>
                  <TableCell key="scope">
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      Scope
                    </Text>
                  </TableCell>
                  <TableCell width="16%" key="status">
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      Status
                    </Text>
                  </TableCell>
                  <TableCell width="17%" key="createdBy">
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      Created By
                    </Text>
                  </TableCell>
                  <TableCell width="13%" key="inserted">
                    <TableSortLabel
                      active
                      direction={sort.direction}
                      onClick={() => onSort('inserted', sort.direction === 'desc' ? 'asc' : 'desc')}
                    >
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                        Date
                      </Text>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width="8%" key="files">
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      Files
                    </Text>
                  </TableCell>
                  <TableCell width="11%" key="actions">
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      Actions
                    </Text>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isReportsLoaded ? (
                  reports?.map((report) => (
                    <TableRow
                      component={Link}
                      to={
                        successStatus.includes(report.get('status')) && reportPath(client.get('id'), report.get('id'))
                      }
                      className={successStatus.includes(report.get('status')) && s.rowLink}
                      key={report.get('id')}
                      hover={successStatus.includes(report.get('status'))}
                    >
                      <TableCell>
                        <Text size={Text.size.s}>{report.get('periodEnd') && formatDate(report.get('periodEnd'))}</Text>
                      </TableCell>
                      <TableCell>
                        <Text size={Text.size.s}>
                          {scopeLabels[report.getIn(['infoParticles', 'scope']) || report.getIn(['infoSa', 'scope'])]}
                        </Text>
                      </TableCell>
                      <TableCell
                        data-tip={(report.getIn(['statusInfo', 'errorMessage']) as string)?.split('\n')?.join('<br />')}
                        data-for="status"
                      >
                        {getReportStatusStamp(report)}
                        <ReactTooltip id="status" html />
                      </TableCell>
                      <TableCell>
                        <Text size={Text.size.s}>{report.get('createdByName')}</Text>
                      </TableCell>
                      <TableCell>
                        <Text size={Text.size.s}>{formatDate(report.get('inserted'))}</Text>
                      </TableCell>
                      <TableCell>
                        <Text size={Text.size.s}>{report.get('actualFileCount')}</Text>
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            toggleReportRemovePopup(report);
                          }}
                          disabled={report.get('status') === 'Deleting'}
                          size="small"
                          variant="text"
                          className={s.removeButton}
                        >
                          <Text size={Text.size.s}>Remove</Text>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRowsLoader rowsNum={perPage} colsNum={7} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {isReportsLoaded && !!reports.size && (
            <div className={s.paginationWrap}>
              <TablePagination
                totalItems={totalItems}
                perPage={perPage}
                page={page}
                handleChangePage={(p) => {
                  history.push(reportsPagePath(client.get('id'), p + 1));
                }}
                onRowsPerPageChange={onPagination}
              />
            </div>
          )}
        </Paper>
      </Row>
    </div>
  ) : (
    <Loader />
  );
};

export default Reports;
