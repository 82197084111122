import { signOut } from '~/data/actions/auth';
import { getConnectionError, getNotFound, getServerError, getSuccess, getUnauthorized } from '~/data/selectors/api';
import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  unauthorized: getUnauthorized(state),
  serverError: getServerError(state),
  notFound: getNotFound(state),
  connectionError: getConnectionError(state),
  success: getSuccess(state),
});

const mapDispatchToProps = {
  signOut,
};

class ApiInterceptor extends React.Component<any> {
  // static propTypes = {
  //   children: PropTypes.node.isRequired,
  //   signOut: PropTypes.func.isRequired,
  //   unauthorized: PropTypes.bool.isRequired,
  // };

  componentDidUpdate(prevProps) {
    if (this.props.unauthorized && !prevProps.unauthorized) {
      this.props.signOut();
    }
  }

  render() {
    return this.props.children;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiInterceptor);
