const prefix = (type) => `dazzler/approvals/${type}`;

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const REJECTION_START = prefix('REJECTION_START');
export const REJECTION_SUCCESS = prefix('REJECTION_SUCCESS');
export const REJECTION_FAILED = prefix('REJECTION_FAILED');

export const APPROVAL_START = prefix('APPROVAL_START');
export const APPROVAL_SUCCESS = prefix('APPROVAL_SUCCESS');
export const APPROVAL_FAILED = prefix('APPROVAL_FAILED');

export const CLEAR = prefix('CLEAR');

export const CLEAR_SINGLE = prefix('CLEAR_SINGLE');
