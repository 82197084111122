import React from 'react';
import { AnyPropsType } from '~/AnyPropsType';
import { Text } from '~/ui/index.js';
import { Button } from '@mui/material';
import { formatDate } from '~/app/utils/format-data';

import s from './styles.module.scss';
import Modal from '~/ui/components/modal/index';

const ApprovePopup = ({ closeFunction, isLoading, request, handleApprove }: AnyPropsType) => (
  <Modal
    open
    closeIcon
    onClose={closeFunction}
    modalTitle={<Text weight={Text.weight.medium}>Approve report</Text>}
    contentText={<Text size={Text.size.l}>Are you sure you want to approve this report?</Text>}
  >
    <p className={s.popupProperty}>
      <Text size={Text.size.s} color={Text.color['medium-grey']}>
        Created By:
        <strong> {request.get('reportCreatedByName')}</strong>
      </Text>
    </p>
    <p className={s.popupProperty}>
      <Text size={Text.size.s} color={Text.color['medium-grey']}>
        Scope:
        <strong> {request.get('reportScope')}</strong>
      </Text>
    </p>
    <p className={s.popupProperty}>
      <Text size={Text.size.s} color={Text.color['medium-grey']}>
        Period End:
        <strong> {formatDate(request.get('reportPeriodEnd'))}</strong>
      </Text>
    </p>
    <p className={s.popupProperty}>
      <Text size={Text.size.s} color={Text.color['medium-grey']}>
        Files:
        <ul className={s.popupListFiles}>
          {request.get('fileNames').map((n) => (
            <li>{n}</li>
          ))}
        </ul>
      </Text>
    </p>

    <div className={s.popupForm}>
      <div className={s.popupFormButtons}>
        <Button onClick={closeFunction} variant="outlined">
          Close
        </Button>
        <Button disabled={isLoading} onClick={handleApprove} variant="contained">
          Approve
        </Button>
      </div>
    </div>
  </Modal>
);

export default ApprovePopup;
