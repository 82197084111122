import ReportsTable from './ReportsTable';
import EditVatPopUp from '~/app/components/core/popup/edit-vat';
import ReportRemovePopup from '~/app/components/core/popup/report-remove';
import UploadFilePopUp from '~/app/components/core/popup/upload-file';
import { reportsPagePath, reportsPath } from '~/app/constants/url/auditor';
import { onFilterHelper } from '~/app/utils/filter-helpers';
import { onPaginationHelper } from '~/app/utils/pagination-helpers';
import { onSortingHelper } from '~/app/utils/sorting-helpers';
import { actions, createAuthURL, useDispatch, useSelector } from '~/data/index.js';
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { clients, scopes, reports } from '~/data/selectors/index';
import { authUrl } from '~/config';

const Reports = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { clientId, page } = useParams<{ clientId: string; page: string }>();

  const client = useSelector((state) => clients.getItem(state, clientId));
  const isClientLoaded = useSelector(clients.itemLoaded);
  const scopesList = useSelector(scopes.getItems);
  const isScopesLoaded = useSelector(scopes.itemsLoaded);
  const reportsList = useSelector(reports.getItems);
  const isReportsLoaded = useSelector(reports.itemsLoaded);
  const totalItems = useSelector(reports.getTotalItems);

  const [scope, setScope] = useState(null);
  const [sort, setSort] = useState({ key: 'inserted', direction: 'desc' });
  const [perPage, setPerPage] = useState(10);
  const [reportRemovePopupState, setReportRemovePopupState] = useState(null);
  const [editVatPopupState, setEditVatPopupState] = useState(null);
  const [uploadPopupState, setUploadPopupState] = useState(false);

  const fetchReports = (params) => dispatch(actions.reports.fetchReports(clientId, params));
  const clearReports = () => dispatch(actions.reports.clear());
  const deleteReport = (reportId) => dispatch(actions.reports.deleteReport(clientId, reportId));
  const createReport = (values, formikActions) => dispatch(actions.reports.createReport(values, formikActions));
  const checkReport = (values, formikActions) => dispatch(actions.reports.checkReport(values, formikActions));
  const editClient = (values, formikActions) => dispatch(actions.clients.editClient(values, formikActions));
  const signOut = () => dispatch(actions.auth.signOut());
  const cancelToken = () => dispatch(actions.auth.cancelToken());
  const fetchClientScopes = () => dispatch(actions.scopes.fetchClientScopes(clientId, undefined));

  const queryParams = {
    sort: sort.key,
    direction: sort.direction,
    scope,
    limit: perPage,
    offset: Number(page) > 1 ? (Number(page) - 1) * perPage : 0,
  };

  useEffect(() => {
    fetchReports(queryParams);
    return () => {
      clearReports();
    };
  }, [page, scope, sort, perPage]);

  useEffect(() => {
    if (!isScopesLoaded && isReportsLoaded) {
      fetchClientScopes();
    }
  }, [isReportsLoaded]);

  const onFilter = (newScope) => {
    setScope(newScope);
    onFilterHelper('scope', scope);
  };

  const onSort = (key, direction) => {
    setSort({ key, direction });
    onSortingHelper(key, direction);
  };

  const onRemove = (reportId) => {
    if (deleteReport(reportId)) {
      history.push({ pathname: reportsPath(clientId), search: window.location.search });
    }
  };

  const handleEditClient = (vatNumber, formikActions) => {
    if (editClient({ clientId, vatNumber }, formikActions)) {
      setEditVatPopupState(null);
    }
  };

  const onPagination = (e) => {
    setPerPage(e.target.value);
    onPaginationHelper(e.target);
  };

  const logout = async () => {
    await cancelToken();
    await signOut();
    window.location.replace(createAuthURL(authUrl));
  };

  if (isReportsLoaded && reportsList.size === 0 && Number(page) > 1) {
    return <Redirect to={reportsPagePath(clientId, Math.ceil(totalItems / perPage))} />;
  }

  return (
    <>
      <ReportsTable
        totalItems={totalItems}
        reports={reportsList}
        client={client}
        scopes={scopesList}
        onFilter={onFilter}
        onSort={onSort}
        scope={scope}
        sort={sort}
        page={page || 1}
        perPage={perPage}
        onPagination={onPagination}
        isReportsLoaded={isReportsLoaded}
        isClientLoaded={isClientLoaded}
        isScopesLoaded={isScopesLoaded}
        toggleReportRemovePopup={setReportRemovePopupState}
        toggleEditVatPopup={setEditVatPopupState}
        toggleUploadPopup={setUploadPopupState}
      />
      {reportRemovePopupState && (
        <ReportRemovePopup
          report={reportRemovePopupState}
          closeFunction={() => setReportRemovePopupState(null)}
          onRemove={onRemove}
        />
      )}

      {editVatPopupState && (
        <EditVatPopUp client={client} editClient={handleEditClient} handleClose={() => setEditVatPopupState(null)} />
      )}

      {uploadPopupState && (
        <UploadFilePopUp
          client={client}
          scopes={scopesList}
          handleClose={() => setUploadPopupState(false)}
          createReport={createReport}
          checkReport={checkReport}
          logout={logout}
        />
      )}
    </>
  );
};

export default Reports;
