/* eslint-disable no-nested-ternary */
import { createAuthURL } from '~/data/index.js';
import { authUrl, clientId } from '~/config';
import { Roles } from '~/data/constants/app';
import * as constants from '~/data/constants/auth';

export const getCurrentUser =
  () =>
  async (dispatch, getState, { api }) => {
    dispatch({ type: constants.CHECK_IS_CLIENT_ADMIN_START });
    try {
      const res = await api.get('users/current');

      dispatch({
        type: constants.CHECK_IS_CLIENT_ADMIN_SUCCESS,
      });
      const role = res.data.isSuperAdmin ? Roles.ADMIN : res.data.isAdmin ? Roles.CLIENT_ADMIN : Roles.AUDITOR;
      return { ...res.data, role };
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.CHECK_IS_CLIENT_ADMIN_FAILED });
      return false;
    }
  };

export const getInitialCredentials =
  () =>
  async (dispatch, getState, { api }) => {
    const authStorage = window.sessionStorage.getItem('auth');
    if (authStorage) {
      api.setAuthorizationHeader(authStorage);
      const user = await dispatch(getCurrentUser());

      dispatch({
        type: constants.SESSION_LOADED,
        payload: user,
      });
    }
  };

export const loginLocally =
  (code) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.SESSION_LOADING });

    try {
      const res = await api.post(`/auth/tokens`, {
        grantType: 'authorization_code',
        code,
        redirectUri: window.location.origin,
        clientId,
      });
      const { idToken, expiresIn, refreshToken } = res.data;

      api.setAuthorizationHeader(idToken);
      window.sessionStorage.setItem('auth', idToken);
      window.localStorage.setItem('refresh', refreshToken);
      window.sessionStorage.setItem('expiresIn', new Date().getTime() / 1000 + expiresIn);
      const user = await dispatch(getCurrentUser());

      dispatch({
        type: constants.SESSION_LOADED,
        payload: user,
      });

      return true;
    } catch (e) {
      dispatch({
        type: constants.SESSION_LOAD_FAILED,
      });
      return false;
    }
  };
export const refresh =
  () =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.REFRESH_LOADING });

    try {
      const res = await api.post(`/auth/tokens`, {
        grantType: 'refresh_token',
        refreshToken: window.localStorage.getItem('refresh'),
        redirectUri: window.location.origin,
        clientId,
      });
      const { idToken, expiresIn, refreshToken } = res.data;

      api.setAuthorizationHeader(idToken);
      window.sessionStorage.setItem('auth', idToken);
      window.localStorage.setItem('refresh', refreshToken);
      window.sessionStorage.setItem('expiresIn', new Date().getTime() / 1000 + expiresIn);

      dispatch({
        type: constants.REFRESH_LOADED,
      });

      return true;
    } catch (e) {
      dispatch({
        type: constants.REFRESH_LOAD_FAILED,
      });

      window.location.replace(createAuthURL(authUrl));
      return false;
    }
  };

export const signOut =
  () =>
  async (dispatch, getState, { api }) => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    api.removeAuthorizationHeader();
    dispatch({
      type: '@@INIT',
    });
    dispatch({
      type: constants.SESSION_CLOSE,
    });
  };

export const cancelToken =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      await api.get(`/auth/logout`, {
        params: {
          clientId,
          refreshToken: window.localStorage.getItem('refresh'),
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
