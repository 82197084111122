import { combineReducers } from 'redux';

import api from './api';
import approvals from './approvals';
import auth from './auth';
import clients from './clients';
import clientsAdmin from './clientsAdmin';
import comparisonrows from './comparisonrows';
import comparisons from './comparisons';
import reportEntities from './entities';
import imports from './imports';
import reports from './reports';
import scopes from './scopes';
import taxonomies from './taxonomies';
import users from './users';
import internal from './internal';

export const entities = {
  clients,
  reports,
  entities: reportEntities,
  comparisons,
  comparisonrows,
  scopes,
  users,
  clientsAdmin,
  approvals,
  taxonomies,
  imports,
  internal,
};

const appReducers = combineReducers({
  auth,
  api,
  ...entities,
});

export default appReducers;

export type RootState = ReturnType<typeof appReducers>;
