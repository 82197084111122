import Loader from '~/app/components/core/preloader';
import { reportComparisonsPagePath } from '~/app/constants/url/auditor';
import { formatCurrencyAmount, formatDate } from '~/app/utils/format-data';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import { Row, Text } from '~/ui/index.js';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import cx from 'classnames';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import TableRowsLoader from '~/ui/components/mui-table/table-loader';
import TablePagination from '~/ui/components/table-pagination/index';

import s from './styles.module.scss';

type ComparisonRowsPropType = {
  client: any;
  isClientLoaded: boolean;
  isReportLoaded: boolean;
  comparison: any;
  isComparisonLoaded: boolean;
  report: any;
  totalCount: number;
  count: number;
  page: string;
  perPage: number;
  onPagination: (e: any) => void;
  sort: any;
  onSort: (e: any) => void;
  onFilter: (key: 'minDifference' | 'tableCode' | 'columnCode' | 'rowCode', value: string, id?: string) => void;
  comparisonRows: any;
  isComparisonRowsLoaded: any;
  onDownloadExcel: any;
  filters: {
    minDifference: string | null;
    tableCode: string | null;
    columnCode: string | null;
    rowCode: string | null;
  };
};
export const ComparisonRows = ({
  client,
  isClientLoaded,
  isReportLoaded,
  comparison,
  isComparisonLoaded,
  report,
  totalCount,
  count,
  page,
  perPage,
  onPagination,
  sort,
  onSort,
  onFilter,
  comparisonRows,
  isComparisonRowsLoaded,
  onDownloadExcel,
  filters,
}: ComparisonRowsPropType) => {
  const history = useHistory();
  const hasTableCode = filters.tableCode?.length >= 3;
  return isClientLoaded && isReportLoaded && isComparisonLoaded && comparison && report && client ? (
    <div className={s.report}>
      <Row className={s.reportTitle}>
        <Text size={Text.size.xl} weight={Text.weight.semiMedium} color={Text.color.colorTextDark}>
          {`Comparisons: ${client.get('name')}`}
        </Text>
      </Row>
      <Box className={s.comparisonWrap}>
        <Text className={s.comparisonWrapTitle} weight={Text.weight.semiMedium} color={Text.color.colorTextDark}>
          General information
        </Text>
        <Box display="flex" flexWrap="nowrap" flexDirection="row" marginLeft="16px">
          <Box className={s.comparisonWrapItem}>
            <Text className={s.comparisonWrapItemTitle}>Comparison</Text>
            <Text className={s.comparisonWrapItemDesc}>{comparison.get('name')}</Text>
          </Box>
          <Box className={s.comparisonWrapItem}>
            <Text className={s.comparisonWrapItemTitle}>Visual Excel</Text>
            <Text className={s.comparisonWrapItemDesc}>
              {comparison.getIn(['visualExcelFileName']) ? (
                <Button
                  onClick={() => onDownloadExcel(comparison.get('id'), comparison.getIn(['visualExcelFileName']))}
                  size="small"
                  variant="text"
                  startIcon={<FileDownloadOutlinedIcon />}
                >
                  <Text size={Text.size.s}>Download</Text>
                </Button>
              ) : (
                <Text size={Text.size.s}>Processing</Text>
              )}
            </Text>
          </Box>
          <Box className={s.comparisonWrapItem}>
            <Text className={s.comparisonWrapItemTitle}>Status </Text>
            <Text className={s.comparisonWrapItemDesc}>{comparison.getIn(['statusInfo', 'message'])}</Text>
          </Box>
          <Box className={s.comparisonWrapItem}>
            <Text className={s.comparisonWrapItemTitle}>Date </Text>
            <Text className={s.comparisonWrapItemDesc}>
              {comparison.get('inserted') && formatDate(comparison.get('inserted'))}
            </Text>
          </Box>
          <Box className={s.comparisonWrapItem}>
            <Text className={s.comparisonWrapItemTitle}>Total</Text>
            <Text className={s.comparisonWrapItemDesc}>{totalCount}</Text>
          </Box>
          <Box className={s.comparisonWrapItem}>
            <Text className={s.comparisonWrapItemTitle}>Filtered </Text>
            <Text className={s.comparisonWrapItemDesc}>{count}</Text>
          </Box>
        </Box>
      </Box>

      <Box className={s.comparisonFiltersWrap} marginBottom="16px">
        <TextField
          className={s.comparisonFiltersInput}
          type="number"
          placeholder="Minimum difference"
          label="Minimum difference"
          defaultValue={filters.minDifference}
          onChange={(e) => onFilter('minDifference', e.target.value, 'min-')}
        />
        <TextField
          className={s.comparisonFiltersInput}
          defaultValue={filters.tableCode}
          placeholder="Table"
          label="Table"
          onChange={(e) => {
            onFilter('tableCode', e.target.value, 'table-');
            if (!e.target.value) {
              onFilter('columnCode', null, 'col-');
              onFilter('rowCode', null, 'row-');
            }
          }}
        />
        <Box
          data-tip={hasTableCode ? undefined : 'Filter on "Table" before the "Row" filter can be used'}
          data-for="rowCodeTooltip"
        >
          <TextField
            className={s.comparisonFiltersInput}
            defaultValue={filters.rowCode}
            placeholder="Row"
            label="Row"
            disabled={!hasTableCode}
            onChange={(e) => onFilter('rowCode', e.target.value, 'row-')}
          />

          <ReactTooltip id="rowCodeTooltip" />
        </Box>
        <Box
          data-tip={hasTableCode ? undefined : 'Filter on "Table" before the "Column" filter can be used'}
          data-for="colCodeTooltip"
        >
          <TextField
            className={s.comparisonFiltersInput}
            defaultValue={filters.columnCode}
            placeholder="Column"
            label="Column"
            disabled={!hasTableCode}
            onChange={(e) => onFilter('columnCode', e.target.value, 'col-')}
          />
          <ReactTooltip id="colCodeTooltip" />
        </Box>
      </Box>

      <Box className={cx(s.comparisonWrap, s.comparisonWrapTable)}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell key="tableCode" width="15%">
                  <TableSortLabel
                    active={sort.key === 'tableCode'}
                    direction={sort.direction}
                    onClick={() => onSort('tableCode')}
                  >
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      Table
                    </Text>
                  </TableSortLabel>
                </TableCell>
                <TableCell key="rowCode" width="12.5%">
                  <TableSortLabel
                    active={sort.key === 'rowCode'}
                    direction={sort.direction}
                    onClick={() => onSort('rowCode')}
                  >
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      Row
                    </Text>
                  </TableSortLabel>
                </TableCell>
                <TableCell key="columnCode" width="12.5%">
                  <TableSortLabel
                    active={sort.key === 'columnCode'}
                    direction={sort.direction}
                    onClick={() => onSort('columnCode')}
                  >
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      Column
                    </Text>
                  </TableSortLabel>
                </TableCell>
                <TableCell className={s.comparisonWrapNumberCell} key="valueFile1" width="20%">
                  <TableSortLabel
                    active={sort.key === 'valueFile1'}
                    direction={sort.direction}
                    onClick={() => onSort('valueFile1')}
                  >
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      {comparison.getIn(['file1', 'name'])}
                    </Text>
                  </TableSortLabel>
                </TableCell>
                <TableCell className={s.comparisonWrapNumberCell} key="valueFile2" width="20%">
                  <TableSortLabel
                    active={sort.key === 'valueFile2'}
                    direction={sort.direction}
                    onClick={() => onSort('valueFile2')}
                  >
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      {comparison.getIn(['file2', 'name'])}
                    </Text>
                  </TableSortLabel>
                </TableCell>
                <TableCell className={s.comparisonWrapNumberCell} key="difference" width="20%">
                  <TableSortLabel
                    active={sort.key === 'difference'}
                    direction={sort.direction}
                    onClick={() => onSort('difference')}
                  >
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      Difference
                    </Text>
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            {isComparisonRowsLoaded && comparisonRows ? (
              <TableBody>
                <ReactTooltip />
                {comparisonRows.size ? (
                  comparisonRows.map((row) => (
                    <TableRow>
                      <TableCell width="15%">
                        <Text className={s.comparisonWrapCellText} size={Text.size.s}>
                          {row.get('tableCode')}
                        </Text>
                      </TableCell>
                      <TableCell width="12.5%">
                        <Text className={s.comparisonWrapCellText} size={Text.size.s}>
                          {row.get('rowCode')}
                        </Text>
                      </TableCell>
                      <TableCell width="12.5%">
                        <Text className={s.comparisonWrapCellText} size={Text.size.s}>
                          {row.get('columnCode')}
                        </Text>
                      </TableCell>
                      <TableCell className={s.comparisonWrapNumberCell} width="20%">
                        <Text className={s.comparisonWrapCellText} size={Text.size.s}>
                          {formatCurrencyAmount(row.get('valueFile1'))}
                        </Text>
                      </TableCell>
                      <TableCell className={s.comparisonWrapNumberCell} width="20%">
                        <Text className={s.comparisonWrapCellText} size={Text.size.s}>
                          {formatCurrencyAmount(row.get('valueFile2'))}
                        </Text>
                      </TableCell>
                      <TableCell className={s.comparisonWrapNumberCell} width="20%">
                        <Text className={s.comparisonWrapCellText} size={Text.size.s}>
                          {formatCurrencyAmount(row.get('difference'))}
                        </Text>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <Box marginTop="20px">
                    <Text>No files found</Text>
                  </Box>
                )}
              </TableBody>
            ) : (
              <TableRowsLoader rowsNum={perPage} colsNum={6} />
            )}
          </Table>
        </TableContainer>

        {isComparisonRowsLoaded && !!comparisonRows.size && (
          <div className={s.reportPagination}>
            <TablePagination
              page={page}
              totalItems={count}
              handleChangePage={(p) =>
                history.push(reportComparisonsPagePath(client.get('id'), report.get('id'), comparison.get('id'), p + 1))
              }
              perPage={perPage}
              onRowsPerPageChange={onPagination}
            />
          </div>
        )}
      </Box>
    </div>
  ) : (
    <Loader />
  );
};
