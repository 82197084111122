import * as constants from '~/data/constants/taxonomies';

export const fetchTaxonomies =
  () =>
  async (dispatch, _, { api }) => {
    try {
      dispatch({ type: constants.LIST_LOAD_START });
      const res = await api.get(`taxonomies`);

      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        payload: res.data.items,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
    }
  };
