import { reportPagePath } from '~/app/constants/url/auditor';
import { onPaginationHelper } from '~/app/utils/pagination-helpers';
import { onSortingHelper } from '~/app/utils/sorting-helpers';
import { actions, selectors } from '~/data/index.js';
import { useAppDispatch, useAppSelector } from '~/data/utils/hooks';
import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import ReportTable from './ReportTable';

const Report = () => {
  const dispatch = useAppDispatch();
  const { clientId, reportId, page } = useParams<{ clientId: string; reportId: string; page: string }>();

  const client = useAppSelector((state) => selectors.clients.getItem(state, clientId));
  const isClientLoaded = useAppSelector(selectors.clients.itemLoaded);
  const report = useAppSelector((state) => selectors.reports.getItem(state, reportId));
  const isReportLoaded = useAppSelector(selectors.reports.itemLoaded);
  const comparisons = useAppSelector(selectors.comparisons.getItems);
  const isComparisonsLoaded = useAppSelector(selectors.comparisons.itemsLoaded);
  const totalItems = useAppSelector(selectors.comparisons.getTotalItems);

  const [sort, setSort] = useState({ key: 'inserted', direction: 'desc' });
  const [perPage, setPerPage] = useState(5);

  const fetchReportComparisons = (filter) =>
    dispatch(actions.comparisons.fetchReportComparisons(clientId, reportId, filter));
  const downloadReportExcel = (fileId, name) =>
    dispatch(actions.reports.downloadReportExcel(clientId, reportId, fileId, name));

  const queryParams = {
    sort: sort.key,
    direction: sort.direction,
    limit: perPage,
    offset: Number(page) > 1 ? (Number(page) - 1) * perPage : 0,
  };

  useEffect(() => {
    fetchReportComparisons(queryParams);
  }, [page, sort, perPage]);

  const onSort = (key) => {
    setSort({ key, direction: sort.direction === 'desc' ? 'asc' : 'desc' });
    onSortingHelper(key, sort.direction === 'desc' ? 'asc' : 'desc');
  };

  const onDownloadExcel = (fileId, name) => {
    downloadReportExcel(fileId, name);
  };

  const onPagination = (value) => {
    setPerPage(value.value);
    onPaginationHelper(value);
  };

  if (isComparisonsLoaded && comparisons.size === 0 && Number(page) > 1) {
    return <Redirect to={reportPagePath(clientId, reportId, Math.ceil(totalItems / perPage))} />;
  }

  return (
    <ReportTable
      report={report}
      client={client}
      isReportLoaded={isReportLoaded}
      isClientLoaded={isClientLoaded}
      isComparisonsLoaded={isComparisonsLoaded}
      onDownloadExcel={onDownloadExcel}
      comparisons={comparisons}
      page={page}
      perPage={perPage}
      onPagination={onPagination}
      totalItems={totalItems}
      onSort={onSort}
      sort={sort}
    />
  );
};

export default Report;
