import * as constants from '~/data/constants/clientsAdmin';
import * as selectors from '~/data/selectors/clientsAdmin';

export const fetchClients =
  (filter) =>
  async (dispatch, _, { api, schema, normalize }) => {
    const newFilter = filter;
    if (newFilter) {
      Object.keys(newFilter).forEach((key) => {
        if (newFilter[key] === undefined) {
          delete newFilter[key];
        }
      });
    }
    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const res = await api.get(`admin/clients`, {
        params: newFilter,
      });
      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        count: res.data.count,
        payload: normalize(res.data.items, [schema.clientsAdmin]),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
    }
  };

export const fetchClient =
  (clientId) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LOAD_START });
    try {
      const res = await api.get(`admin/clients/${clientId}`);

      dispatch({
        type: constants.LOAD_SUCCESS,
        payload: normalize(res.data, schema.clientsAdmin),
      });
    } catch (e) {
      dispatch({ type: constants.LOAD_FAILED });
    }
  };

export const editClient =
  (values, actions) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.UPDATE_START });
    actions.setSubmitting(true);
    try {
      const res = await api.patch(`admin/clients/${values.clientId}`, values);
      actions.setSubmitting(false);
      actions.setStatus(values.clientId);

      dispatch({
        type: constants.UPDATE_SUCCESS,
        payload: normalize(res.data, schema.clientsAdmin),
      });

      return res.data;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.UPDATE_FAILED });
      actions.setSubmitting(false);

      throw e;
    }
  };

export const addClient =
  (values, actions) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.CREATION_START });
    actions.setSubmitting(true);
    try {
      const res = await api.post(`admin/clients`, values);
      actions.setSubmitting(false);
      actions.resetForm();
      dispatch({
        type: constants.CREATION_SUCCESS,
      });

      return res.data.id;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.CREATION_FAILED });
      actions.setSubmitting(false);
      if (e.response && e.response.data) {
        actions.setErrors({ message: e.response.data.message });
      }

      throw e;
    }
  };

export const deleteClient =
  (clientId) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.DELETE_CLIENT_START, id: clientId });
    try {
      await api.delete(`/admin/clients/${clientId}`);

      dispatch({
        type: constants.DELETE_CLIENT_SUCCESS,
        id: clientId,
      });
      return true;
    } catch (e) {
      dispatch({ type: constants.DELETE_CLIENT_FAILED });
      return false;
    }
  };

export const fetchConnectedClients =
  (filter, userId) =>
  async (dispatch, _, { api, schema, normalize }) => {
    const newFilter = filter;
    if (newFilter) {
      Object.keys(newFilter).forEach((key) => {
        if (newFilter[key] === undefined) {
          delete newFilter[key];
        }
      });
    }
    dispatch({ type: constants.CONNECTED_CLIENTS_LOAD_START });
    try {
      const res = await api.get(`admin/users/${userId}/clients/connected`, {
        params: newFilter,
      });
      dispatch({
        type: constants.CONNECTED_CLIENTS_LOAD_SUCCESS,
        count: res.data.count,
        payload: normalize(res.data.items, [schema.clientsAdmin]),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.CONNECTED_CLIENTS_LOAD_FAILED });
    }
  };

export const fetchAvailableClients =
  (filter, userId) =>
  async (dispatch, _, { api, schema, normalize }) => {
    const newFilter = filter;
    if (newFilter) {
      Object.keys(newFilter).forEach((key) => {
        if (newFilter[key] === undefined) {
          delete newFilter[key];
        }
      });
    }
    dispatch({ type: constants.AVAILABLE_CLIENTS_LOAD_START });
    try {
      const res = await api.get(`admin/users/${userId}/clients/available`, {
        params: newFilter,
      });
      dispatch({
        type: constants.AVAILABLE_CLIENTS_LOAD_SUCCESS,
        count: res.data.count,
        payload: normalize(res.data.items, [schema.clientsAdmin]),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.AVAILABLE_CLIENTS_LOAD_FAILED });
    }
  };

export const deleteConnectedClient =
  (userId, clientId) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.DELETE_CONNECTED_CLIENT_START, id: userId });
    try {
      await api.delete(`/admin/users/${userId}/clients/${clientId}`);

      dispatch({
        type: constants.DELETE_CONNECTED_CLIENT_SUCCESS,
        id: clientId,
      });
      return true;
    } catch (e) {
      dispatch({ type: constants.DELETE_CONNECTED_CLIENT_FAILED });
      return false;
    }
  };

export const connectClient =
  (userId, clientId) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.CONNECT_CLIENT_START });
    try {
      await api.post(`/admin/users/${userId}/clients/${clientId}`);

      dispatch({
        type: constants.CONNECT_CLIENT_SUCCESS,
      });

      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.CONNECT_CLIENT_FAILED });

      throw e;
    }
  };

export const connectClients =
  (userId, values, setError?) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.CONNECT_CLIENTS_START }); //
    try {
      await api.post(`/admin/users/${userId}/clients`, values);

      dispatch({
        type: constants.CONNECT_CLIENTS_SUCCESS,
      });

      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.CONNECT_CLIENTS_FAILED }); //
      if (e.response && e.response.data) {
        setError({ message: e.response.data.message });
      }
      throw e;
    }
  };

export const changeUserRole =
  (userId, clientId) =>
  async (dispatch, getState, { api }) => {
    dispatch({ type: constants.CHANGE_USER_START });
    try {
      const isAdmin = selectors.getItemIsAdmin(getState(), clientId, userId);
      await api.patch(`/admin/users/${userId}/clients/${clientId}`, {
        isAdmin: !isAdmin,
      });

      dispatch({
        type: constants.CHANGE_USER_SUCCESS,
        payload: {
          isAdmin: !isAdmin,
          userId,
          clientId,
        },
      });

      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.CHANGE_USER_FAILED });

      throw e;
    }
  };
export const clear = () => ({ type: constants.CLEAR });

export const clearSingle = () => ({ type: constants.CLEAR_SINGLE });
