export const saveBlob = (blob, fileName) => {
  if ((navigator as any).msSaveBlob) {
    (navigator as any).msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }
};

export const saveBlobFromResponse = async (res) => {
  const filename = res.headers['content-disposition']?.match(/filename=(.+);/i)?.[1]?.replace('filename=', '');
  saveBlob(res.data, filename);
  return filename;
};
