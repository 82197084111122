import { Region } from '~/data/utils/types';

export const Roles = {
  ADMIN: 'ADMIN',
  CLIENT_ADMIN: 'CLIENT_ADMIN',
  AUDITOR: 'AUDITOR',
};

export const RegionFileType = {
  [Region.Belgium]: 'XBRL',
  [Region.SouthAfrica]: 'ZIP',
};

export const ReportInfoInitialValues = {
  [Region.Belgium]: {
    infoParticles: {
      scope: '',
    },
  },
  [Region.SouthAfrica]: {
    infoSa: {
      scope: '',
    },
  },
};

export const ScopeFileName = {
  [Region.Belgium]: 'infoParticles.scope',
  [Region.SouthAfrica]: 'infoSa.scope',
};
