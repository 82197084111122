import * as constants from '~/data/constants/internal';
import { saveBlobFromResponse } from '~/data/utils/helpers';
import { format } from 'date-fns';

export const updatePackages =
  () =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.UPDATE_START });
    try {
      const res = await api.post(`internal/atome-particles/update-packages`);

      dispatch({
        type: constants.UPDATE_SUCCESS,
        payload: res.data,
      });

      return res.data;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.UPDATE_FAILED });

      throw e;
    }
  };

export const fetchJobStatus =
  (jobId: string) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.JOB_FETCH_START });
    try {
      const res = await api.get(`/internal/jobs/${jobId}`);

      dispatch({
        type: constants.JOB_FETCH_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (e) {
      dispatch({ type: constants.JOB_FETCH_FAILED });
      throw e;
    }
  };

export const fetchAuditLogs =
  (filter) =>
  async (dispatch, _, { api }) => {
    const newFilter = filter;
    if (newFilter) {
      Object.keys(newFilter).forEach((key) => {
        if (newFilter[key] === undefined) {
          delete newFilter[key];
        }
      });
    }
    dispatch({ type: constants.LOG_FETCH_START });
    try {
      const res = await api.get(`internal/audit-logs`, {
        params: newFilter,
      });
      dispatch({
        type: constants.LOG_FETCH_SUCCESS,
        count: res.data.count,
        payload: res.data.items,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LOG_FETCH_FAILED });
      throw e;
    }
  };

export const downloadClientUsageReport =
  (start: Date, end: Date, clientIds: string[]) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.DOWNLOAD_USAGE_START });

    try {
      const res = await api.get(`admin/clients/usage-reports`, {
        params: {
          start: format(start, 'yyyy-MM-dd'),
          end: format(end, 'yyyy-MM-dd'),
        },
        responseType: 'blob',
      });

      saveBlobFromResponse(res);
      dispatch({
        type: constants.DOWNLOAD_USAGE_SUCCESS,
      });

      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.DOWNLOAD_USAGE_FAILED });

      throw e;
    }
  };

export const clear = () => ({ type: constants.CLEAR });
