import Breadcrumbs from '~/app/components/core/breadcrumbs/index';
import { reportComparisonPath, reportComparisonsPath, reportPagePath, reportPath } from '~/app/constants/url/auditor';
import { formatDate } from '~/app/utils/format-data';
import { actions, selectors, useDispatch, useSelector } from '~/data/index.js';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';

import { Comparison } from './comparison/index';
import { ReportComparisonsRouter } from './comparisons/index';
import { Report } from './report/index';

const ReportRouter = () => {
  const dispatch = useDispatch();
  const { clientId, reportId } = useParams<{ clientId: string; reportId: string }>();

  const report = useSelector((state) => selectors.reports.getItem(state, reportId));
  const isReportLoaded = useSelector(selectors.reports.itemLoaded);

  const fetchReport = () => dispatch(actions.reports.fetchReport(clientId, reportId));
  const clearReport = () => dispatch(actions.reports.clearSingle());

  useEffect(() => {
    if (!isReportLoaded) {
      fetchReport();
    }

    return () => {
      clearReport();
    };
  }, []);

  return (
    <>
      <Breadcrumbs.Anchor
        path={reportPath(':clientId', ':reportId')}
        link={reportPath(clientId, reportId)}
        component={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <span title="Report">
            {isReportLoaded ? `Report ${report.get('periodEnd') && formatDate(report.get('periodEnd'))}` : 'Report'}
          </span>
        }
      />
      <Switch>
        <Route path={reportPagePath(':clientId', ':reportId', ':page([1-9]\\d*)')} component={Report} />
        <Route
          path={reportComparisonsPath(':clientId', ':reportId', ':comparisonId')}
          component={ReportComparisonsRouter}
        />
        <Route exact path={reportComparisonPath(':clientId', ':reportId', ':fileId')} component={Comparison} />
        <Redirect from={reportPath(clientId, reportId)} to={reportPagePath(clientId, reportId, 1)} />
      </Switch>
    </>
  );
};

export default ReportRouter;
