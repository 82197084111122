import './global.css';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { muiTheme } from '~/ui/utils/MUItheme';

import Dazzler from '~/app/components/pages/index';
import { actions, createStore } from '~/data/index';
import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { intercomId } from '~/config';

import history from '~/app/history';

const store = createStore();

store.dispatch(actions.auth.getInitialCredentials() as any);

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <IntercomProvider appId={intercomId} autoBoot apiBase="https://api-iam.eu.intercom.io">
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={muiTheme}>
              <Dazzler />
            </ThemeProvider>
          </StyledEngineProvider>
        </IntercomProvider>
      </Router>
    </Provider>
  );
}

const rootElement = document.getElementById('root')!;

render(<App />, rootElement);
