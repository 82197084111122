import { useDebounceValue } from '~/app/utils/debounce';
import { onFilterHelper } from '~/app/utils/filter-helpers';
import { onPaginationHelper } from '~/app/utils/pagination-helpers';
import { onSortingHelper } from '~/app/utils/sorting-helpers';
import { Row, SelectInput, Text } from '~/ui/index.js';
import { actions, Roles, selectors } from '~/data/index.js';
import { useAppDispatch, useAppSelector } from '~/data/utils/hooks';
import React, { useEffect, useState } from 'react';
import { Redirect, useParams, Link, useHistory } from 'react-router-dom';
import { manageClientPath, manageClientsPagePath } from '~/app/constants/url/auditor';
import Search from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import TableRowsLoader from '~/ui/components/mui-table/table-loader';
import TablePagination from '~/ui/components/table-pagination/index';
import { DeleteClientPopup, AddClientPopup } from '../actions/index';

import s from './styles.module.scss';

const options = [
  { value: { key: 'lastUpdated', direction: 'DESC' }, label: 'Recent update' },
  { value: { key: 'name', direction: 'ASC' }, label: 'Alphanumeric' },
];

const ClientsContainer = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { page } = useParams<{ page: string }>();

  const clients = useAppSelector(selectors.clientsAdmin.getItems);
  const isClientsLoaded = useAppSelector(selectors.clientsAdmin.itemsLoaded);
  const totalItems = useAppSelector(selectors.clientsAdmin.getTotalItems);
  const role = useAppSelector(selectors.auth.getSessionRole);
  const user = useAppSelector(selectors.auth.getSessionUser);

  const fetchClients = (filter) => dispatch(actions.clientsAdmin.fetchClients(filter));
  const clear = () => dispatch(actions.clientsAdmin.clear());
  const deleteClient = (clientId) => dispatch(actions.clientsAdmin.deleteClient(clientId));
  const addClient = (values, formikActions) => dispatch(actions.clientsAdmin.addClient(values, formikActions));

  const [inputValue, setInputValue] = useState('');
  const [sort, setSort] = useState({ key: 'name', direction: 'asc' });
  const [perPage, setPerPage] = useState(10);
  const [clientToDelete, setClientToDelete] = useState(null);
  const [showAddClientPopup, setShowAddClientPopup] = useState(false);

  const debouncedSearchText = useDebounceValue(inputValue);

  const queryParams = {
    search: debouncedSearchText,
    sort: sort.key,
    direction: sort.direction,
    limit: perPage,
    offset: Number(page) > 1 ? (Number(page) - 1) * perPage : 0,
  };

  useEffect(() => {
    fetchClients(queryParams);
    return () => {
      clear();
    };
  }, [debouncedSearchText, sort, page, perPage]);

  const onSearch = (newSearch) => {
    setInputValue(newSearch);
    onFilterHelper('search', newSearch);
  };

  const onSort = (newSort) => {
    setSort(newSort);
    onSortingHelper(newSort.key, newSort.direction);
  };

  const onPagination = (e) => {
    setPerPage(e.target.value);
    onPaginationHelper(e.target);
  };

  const handleDeleteClient = () => {
    const res = deleteClient(clientToDelete.get('id'));
    if (res) {
      setClientToDelete(null);
    }
  };

  if (isClientsLoaded && clients.size === 0 && Number(page) > 1) {
    return <Redirect to={manageClientsPagePath(Math.ceil(totalItems / perPage))} />;
  }
  const isSuperAdmin = role === Roles.ADMIN;

  return (
    <>
      <div className={s.clients}>
        <Row className={s.clientsRow}>
          <Text size={Text.size.xl} weight={Text.weight.semiMedium} color={Text.color.colorTextDark}>
            Manage clients
          </Text>
        </Row>
        <Box display="flex" justifyContent="space-between" marginBottom="14px">
          <Box display="flex" flexWrap="nowrap">
            <Box width={345} marginRight="10px">
              <TextField
                placeholder="Search"
                InputProps={{
                  startAdornment: <Search htmlColor={theme.palette.secondary.light} />,
                }}
                onChange={({ target: { value } }) => onSearch(value)}
                value={inputValue}
                size="small"
                color="primary"
                fullWidth
              />
            </Box>
            <Box width={345}>
              <SelectInput
                name="sort-clients"
                dataCy="clientSort"
                options={options}
                value={options.filter((option) => option.value.key === sort.key)}
                onChange={(e) => onSort(e.value)}
              />
            </Box>
          </Box>

          {(isSuperAdmin || user.adminRegion) && (
            <Button
              variant="contained"
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={() => setShowAddClientPopup(true)}
            >
              Add client
            </Button>
          )}
        </Box>

        <Box>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="40%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                        Client name
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                        VAT Number
                      </Text>
                    </TableCell>
                    <TableCell width="10%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                        Actions
                      </Text>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isClientsLoaded ? (
                    <>
                      {clients.size ? (
                        clients.map((client) => (
                          <TableRow
                            hover
                            className={s.clientsTableRow}
                            component={Link}
                            to={manageClientPath(client.get('id'))}
                            key={client.get('id')}
                          >
                            <TableCell>
                              <Text size={Text.size.s}>{client.get('name')}</Text>
                            </TableCell>
                            <TableCell>
                              <Text size={Text.size.s}>{client.get('vatNumber')}</Text>
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="text"
                                size="small"
                                className={s.clientsTableRowButton}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setClientToDelete(client);
                                }}
                              >
                                <Text size={Text.size.s}>Remove</Text>
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <Box marginTop="20px">
                          <Text>No clients found</Text>
                        </Box>
                      )}
                    </>
                  ) : (
                    <TableRowsLoader rowsNum={perPage} colsNum={5} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {isClientsLoaded && !!clients.size && (
              <TablePagination
                page={page || 1}
                totalItems={totalItems}
                handleChangePage={(p) => history.push(manageClientsPagePath(p + 1))}
                perPage={perPage}
                onRowsPerPageChange={onPagination}
              />
            )}
          </Paper>
        </Box>
      </div>

      {clientToDelete && (
        <DeleteClientPopup
          closeFunction={() => setClientToDelete(null)}
          clientName={clientToDelete?.get('name')}
          handleDeleteClient={handleDeleteClient}
        />
      )}

      {showAddClientPopup && (
        <AddClientPopup
          closeFunction={() => setShowAddClientPopup(false)}
          addClient={addClient}
          isSuperAdmin={isSuperAdmin}
          user={user}
        />
      )}
    </>
  );
};

export default ClientsContainer;
