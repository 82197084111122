import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useDebounceValue } from '~/app/utils/debounce';
import { onPaginationHelper } from '~/app/utils/pagination-helpers';
import { actions, Roles, selectors, useDispatch } from '~/data/index.js';
import { useAppSelector } from '~/data/utils/hooks';
import {
  userEditConnectedClientsPagePath,
  userConnectedClientsPath,
  userEditConnectedClientsPath,
} from '~/app/constants/url/auditor';
import { Row, Text } from '~/ui/index.js';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import Search from '@mui/icons-material/Search';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {
  Box,
  Button,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from '@mui/material';
import TableRowsLoader from '~/ui/components/mui-table/table-loader';
import { ChangeRolePopup, DeleteUserPopup } from '../../manage-clients/actions/index';
import TablePagination from '~/ui/components/table-pagination/index';
import { EditUserPopUp, AvailableClientsPopup } from '../actions/index';

import s from './styles.module.scss';

const User = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { page, userId } = useParams<{ page: string; userId: string }>();

  const connectedClients = useAppSelector(selectors.clientsAdmin.getConnectedClients);
  const isConnectedClientsLoaded = useAppSelector(selectors.clientsAdmin.connectedClientsLoaded);
  const totalItems = useAppSelector(selectors.clientsAdmin.getTotalConnectedClients);
  const isRoleChanging = useAppSelector(selectors.clientsAdmin.getRoleChanging);
  const role = useAppSelector(selectors.auth.getSessionRole);
  const user = useAppSelector((state) => selectors.users.getItem(state, userId));
  const isUserLoaded = useAppSelector(selectors.users.itemLoaded);

  const fetchConnectedClients = (filter) => dispatch(actions.clientsAdmin.fetchConnectedClients(filter, userId));
  const clear = () => dispatch(actions.clientsAdmin.clear());
  const deleteConnectedClient = (clientId) => dispatch(actions.clientsAdmin.deleteConnectedClient(userId, clientId));
  const changeUserRole = (clientId) => dispatch(actions.clientsAdmin.changeUserRole(userId, clientId));
  const fetchUser = () => dispatch(actions.users.fetchUser(userId));
  const editUser = (values, formikActions) => dispatch(actions.users.editUser(values, formikActions, userId));

  const [inputValue, setInputValue] = useState('');

  const [perPage, setPerPage] = useState(10);

  const [clientToDelete, setClientToDelete] = useState(null);
  const [isChangingRoleShown, setIsChangingRoleShown] = useState(false);
  const [clientIdToChangeRole, setClientIdToChangeRole] = useState(null);
  const [showEditUserPopup, setShowEditUserPopup] = useState(false);
  const [showAvailableClientsPopup, setShowAvailableClientsPopup] = useState(false);

  const debouncedSearchText = useDebounceValue(inputValue);

  const queryParams = {
    search: debouncedSearchText,
    sort: 'name',
    direction: 'asc',
    limit: perPage,
    offset: Number(page) > 1 ? (Number(page) - 1) * perPage : 0,
  };

  useEffect(() => {
    if (!isUserLoaded) {
      fetchUser();
    }
    fetchConnectedClients(queryParams);
    return () => {
      clear();
    };
  }, [debouncedSearchText, page, perPage]);

  const onSearch = (search) => {
    setInputValue(search);
  };

  const onPagination = (e) => {
    setPerPage(e.target.value);
    onPaginationHelper(e.target);
  };

  const onDelete = async (clientId) => {
    if (await deleteConnectedClient(clientId)) {
      history.push(userEditConnectedClientsPath(userId));
    }
  };

  const handleChangingRole = (clientId) => {
    setClientIdToChangeRole(clientId);
    setIsChangingRoleShown(true);
  };

  const toggleChangingRole = () => {
    setIsChangingRoleShown(!isChangingRoleShown);
  };

  const changeRole = async (clientId) => {
    if (await changeUserRole(clientId)) {
      toggleChangingRole();
    }
  };

  if (isConnectedClientsLoaded && connectedClients.size === 0 && Number(page) > 1) {
    return <Redirect to={userConnectedClientsPath(userId)} />;
  }

  const isSuperAdmin = role === Roles.ADMIN;

  return (
    <div className={s.clients}>
      <Row className={s.clientsRow}>
        <Box display="flex" alignItems="center">
          <Text size={Text.size.xl} weight={Text.weight.medium} color={Text.color.colorTextDark}>
            {user.get('firstName') && user.get('familyName')
              ? `${user.get('firstName')} ${user.get('familyName')} `
              : 'No name'}
          </Text>
          <Button
            className={s.editButton}
            onClick={() => setShowEditUserPopup(true)}
            color="primary"
            startIcon={<BorderColorRoundedIcon />}
            size="medium"
          >
            <Text color={Text.color.orange} size={Text.size.s} weight={Text.weight.medium}>
              EDIT
            </Text>
          </Button>
        </Box>
      </Row>
      <Box marginBottom="18px">
        <Text> Connected clients</Text>
      </Box>

      <Box display="flex" justifyContent="space-between" marginBottom="14px">
        <Box width={345}>
          <TextField
            placeholder="Search client"
            InputProps={{
              startAdornment: <Search htmlColor={theme.palette.secondary.light} />,
            }}
            onChange={({ target: { value } }) => onSearch(value)}
            value={inputValue}
            size="small"
            color="primary"
            fullWidth
          />
        </Box>

        {isSuperAdmin && (
          <Button
            variant="contained"
            onClick={() => setShowAvailableClientsPopup(true)}
            startIcon={<AddCircleOutlineOutlinedIcon />}
          >
            Add client
          </Button>
        )}
      </Box>

      <Box>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="40%">
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      Client name
                    </Text>
                  </TableCell>
                  <TableCell width="35%">
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      VAT number
                    </Text>
                  </TableCell>

                  {isSuperAdmin && (
                    <TableCell>
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                        Admin role
                      </Text>
                    </TableCell>
                  )}

                  <TableCell width="10%">
                    <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.semiMedium}>
                      Action
                    </Text>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isConnectedClientsLoaded && !isRoleChanging ? (
                  <>
                    {connectedClients.size ? (
                      connectedClients.map((client) => (
                        <TableRow key={client.get('id')}>
                          <TableCell>
                            <Text size={Text.size.s} font={Text.color.colorTextMedium} weight={Text.weight.semiMedium}>
                              {client.get('name')}
                            </Text>
                          </TableCell>

                          <TableCell>
                            <Text size={Text.size.s} font={Text.color.colorTextMedium} weight={Text.weight.semiMedium}>
                              {client.get('vatNumber')}
                            </Text>
                          </TableCell>

                          {isSuperAdmin && (
                            <TableCell>
                              <Switch
                                size="small"
                                checked={
                                  client.has('clientUsers') &&
                                  client.get('clientUsers').size &&
                                  client.get('clientUsers').first().get('isAdmin')
                                }
                                id={client.get('id')}
                                onChange={() => handleChangingRole(client.get('id'))}
                                disabled={!client.has('clientUsers') || !client.get('clientUsers').size}
                              />
                            </TableCell>
                          )}

                          <TableCell>
                            <Button
                              variant="text"
                              onClick={() => setClientToDelete(client)}
                              disabled={
                                !client.has('clientUsers') ||
                                !client.get('clientUsers').size ||
                                client.get('clientUsers').first().get('type') !== 'Manual'
                              }
                              className={s.removeButton}
                            >
                              <Text size={Text.size.s} weight={Text.weight.semiMedium}>
                                Remove
                              </Text>
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <Text>No clients found</Text>
                    )}
                  </>
                ) : (
                  <TableRowsLoader rowsNum={perPage} colsNum={isSuperAdmin ? 4 : 3} />
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {isConnectedClientsLoaded && !!connectedClients.size && (
            <div className={s.clientsPagination}>
              <TablePagination
                totalItems={totalItems}
                perPage={perPage}
                page={page || 1}
                handleChangePage={(p) => {
                  history.push(userEditConnectedClientsPagePath(userId, p + 1));
                }}
                onRowsPerPageChange={onPagination}
              />
            </div>
          )}
        </Paper>
      </Box>

      {clientToDelete && (
        <DeleteUserPopup
          closeFunction={() => setClientToDelete(null)}
          text="Are you sure you want to remove access for this client?"
          handleDeleteUser={() => onDelete(clientToDelete.get('id'))}
        />
      )}
      {isChangingRoleShown && (
        <ChangeRolePopup closeFunction={toggleChangingRole} handleChangeRole={() => changeRole(clientIdToChangeRole)} />
      )}

      {showEditUserPopup && (
        <EditUserPopUp
          handleClose={() => setShowEditUserPopup(false)}
          isSuperAdmin={isSuperAdmin}
          user={user}
          handleEditUser={editUser}
        />
      )}

      {showAvailableClientsPopup && <AvailableClientsPopup closeFunction={() => setShowAvailableClientsPopup(false)} />}
    </div>
  );
};

export default User;
