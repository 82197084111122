import * as constants from '~/data/constants/imports';
import { saveBlob } from '~/data/utils/helpers';

export const fetchList =
  (filter) =>
  async (dispatch, _, { api, schema, normalize }) => {
    const newFilter = filter;
    if (newFilter) {
      Object.keys(newFilter).forEach((key) => {
        if (newFilter[key] === undefined) {
          delete newFilter[key];
        }
      });
    }
    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const res = await api.get(`imports/eba-transparency`, {
        params: newFilter,
      });
      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        count: res.data.count,
        payload: normalize(res.data.items, [schema.ebaImport]),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
    }
  };

export const upload =
  (file, setError) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.UPLOAD_START });
    try {
      if (file && file instanceof File) {
        const formFile = new FormData();
        formFile.append('file', file);
        await api.post(`imports/eba-transparency`, formFile);

        dispatch({
          type: constants.UPLOAD_SUCCESS,
        });
      }

      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.UPLOAD_FAILED });

      setError(true);
    }
  };

export const download =
  (importId, fileName) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.DOWNLOAD_START });

    try {
      const { data: blob } = await api.get(`/imports/eba-transparency/${importId}`, {
        responseType: 'blob',
      });

      saveBlob(blob, fileName);
      dispatch({
        type: constants.DOWNLOAD_SUCCESS,
      });

      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.DOWNLOAD_FAILED });

      throw e;
    }
  };

export const clear = () => ({ type: constants.CLEAR });

export const clearSingle = () => ({ type: constants.CLEAR_SINGLE });
