import axios, { AxiosInstance } from 'axios';
import { createAuthURL } from '~/data/index.js';
import * as apiConstants from '~/data/constants/api';
import * as authConstants from '~/data/constants/auth';
import { HTTPError } from '~/data/utils/errors';
import { getQueryStringParams } from '~/data/utils/query-string';
import { Store } from 'redux';
import { authUrl } from '~/config';

const wait = (time) => new Promise((res) => setTimeout(res, time));

class API {
  axios: AxiosInstance;
  store: Store;
  constructor(store, ...axiosArgs) {
    this.errorInterceptor = this.errorInterceptor.bind(this);
    this.successInterceptor = this.successInterceptor.bind(this);
    this.setAuthorizationHeader = this.setAuthorizationHeader.bind(this);
    this.removeAuthorizationHeader = this.removeAuthorizationHeader.bind(this);

    this.axios = axios.create(...axiosArgs);
    this.store = store;
    this.axios.interceptors.response.use(this.successInterceptor, this.errorInterceptor);

    if (window) (window as any).api = this;
  }

  static noRefresh = 'No token';

  successInterceptor(response) {
    this.store.dispatch({ type: apiConstants.SUCCESS });

    return response;
  }

  errorInterceptor(error) {
    if (error.response) {
      switch (error.response.status) {
        case 503:
          this.store.dispatch({ type: apiConstants.SERVICE_UNAVAILABLE, error: error.response.data });
          throw new HTTPError('', error.response.status);

        case 400:
          this.store.dispatch({ type: apiConstants.UNEXPECTED_ERROR, error: error.response.data });
          throw new HTTPError('Unexpected error', error);
        case 401:
          this.store.dispatch({
            type: authConstants.SESSION_CLOSE,
          });
          window.sessionStorage.removeItem('auth');
          this.removeAuthorizationHeader();
          if (getQueryStringParams(window.location.search).mode === 'integration') {
            window.parent.postMessage({ code: '401' }, '*');
          } else {
            window.location.replace(createAuthURL(authUrl));
          }
          break;
        case 403:
          this.store.dispatch({ type: apiConstants.FORBIDDEN, error: error.response.data });
          throw new HTTPError('Forbidden', error.response.status);
        case 500:
          this.store.dispatch({ type: apiConstants.SERVER_ERROR, error: error.response.data });
          throw new HTTPError('Server Error', error.response.status);
        // case 404:
        //   this.store.dispatch({ type: apiConstants.NOT_FOUND, error: error.response.data });
        //   throw new HTTPError('Not Found', error.response.status);
        default:
          break;
      }
    } else {
      this.store.dispatch({ type: apiConstants.CONNECTION_ERROR });
      // throw new ConnectionError('No connection');
      if (error.config) {
        // const reqConfig = error.config;

        return wait(3001).then(() => Promise.reject(error) /* this.axios.request(reqConfig) */);
      }
    }
    return Promise.reject(error);
  }

  setAuthorizationHeader = (apiKey) => {
    this.axios.defaults.headers.common.Authorization = `Bearer ${apiKey}`;
  };

  setRefresh(refresh) {
    this.axios.defaults.headers.common['x-refresh-token'] = refresh;
  }

  removeAuthorizationHeader() {
    delete this.axios.defaults.headers.common.Authorization;
  }

  get = (...args) => this.axios.get.apply(this, args);

  post = (...args) => this.axios.post.apply(this, args);

  patch = (...args) => this.axios.patch.apply(this, args);

  put = (...args) => this.axios.put.apply(this, args);

  delete = (...args) => this.axios.delete.apply(this, args);
}

export default API;
