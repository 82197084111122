import * as constants from '~/data/constants/scopes';

export const fetchClientScopes =
  (clientId, filter) =>
  async (dispatch, _, { api, schema, normalize }) => {
    const newFilter = filter;
    if (newFilter) {
      Object.keys(newFilter).forEach((key) => {
        if (
          newFilter[key] === undefined ||
          newFilter[key] === false ||
          newFilter[key] === '' ||
          newFilter[key] === null
        ) {
          delete newFilter[key];
        }
      });
    }

    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const res = await api.get(`clients/${clientId}/scopes`, {
        params: newFilter,
      });

      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        count: res.data.count,
        payload: normalize(
          Object.keys(res.data).map((key, index) => ({ id: index, name: key, active: res.data[key] })),
          [schema.scope],
        ),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
    }
  };

export const clear = () => ({ type: constants.CLEAR });

export const clearSingle = () => ({ type: constants.CLEAR_SINGLE });
