/* eslint-disable import/no-unresolved */
import { AnyPropsType } from '~/AnyPropsType';
import Breadcrumbs from '~/app/components/core/breadcrumbs/index';
import {
  adminPath,
  approvalsPath,
  clientsPath,
  ebaImportsPath,
  manageClientsPath,
  usersPath,
} from '~/app/constants/url/auditor';
import cx from 'classnames';
import Logo from '/logo.svg?url';
import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Text } from '~/ui/index.js';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import s from './styles.module.scss';
import { Box, Button } from '@mui/material';
import SupportPopup from '../popup/support';

const Header = ({ logout, isAdmin, isSuperAdmin }: AnyPropsType) => {
  const [isSupportShown, setSupportShown] = useState(false);

  return (
    <header className={s.header}>
      <div className={s.headerContent}>
        <div className={s.headerContentTop}>
          <div className={s.headerContentTopContent}>
            <Link to="/" className={s.headerLink}>
              <img src={Logo} alt="Regulatory Reporting Insights" />
            </Link>{' '}
            <div className={s.headerContentWrap}>
              {isAdmin && (
                <>
                  <NavLink
                    to={clientsPath}
                    className={(isActive) =>
                      isActive
                        ? cx(s.headerContentTopContentNavigationItem, s.headerContentTopContentNavigationItemActive)
                        : s.headerContentTopContentNavigationItem
                    }
                  >
                    <Text size={Text.size.s}>Client reports</Text>
                  </NavLink>

                  <NavLink
                    to={approvalsPath}
                    className={(isActive) =>
                      isActive
                        ? cx(s.headerContentTopContentNavigationItem, s.headerContentTopContentNavigationItemActive)
                        : s.headerContentTopContentNavigationItem
                    }
                  >
                    <Text size={Text.size.s}>Approvals</Text>
                  </NavLink>

                  <NavLink
                    to={usersPath}
                    className={(isActive) =>
                      isActive
                        ? cx(s.headerContentTopContentNavigationItem, s.headerContentTopContentNavigationItemActive)
                        : s.headerContentTopContentNavigationItem
                    }
                  >
                    <Text size={Text.size.s}>Manage Users</Text>
                  </NavLink>

                  {isSuperAdmin && (
                    <>
                      <NavLink
                        to={manageClientsPath}
                        className={(isActive) =>
                          isActive
                            ? cx(s.headerContentTopContentNavigationItem, s.headerContentTopContentNavigationItemActive)
                            : s.headerContentTopContentNavigationItem
                        }
                      >
                        <Text size={Text.size.s}>Manage Clients</Text>
                      </NavLink>
                      <NavLink
                        to={adminPath}
                        className={(isActive) =>
                          isActive
                            ? cx(s.headerContentTopContentNavigationItem, s.headerContentTopContentNavigationItemActive)
                            : s.headerContentTopContentNavigationItem
                        }
                      >
                        <Text size={Text.size.s}>Admin panel</Text>
                      </NavLink>
                    </>
                  )}
                </>
              )}

              <Box marginLeft="auto" display="flex">
                <Button variant="text" onClick={logout} className={s.headerContentTopContentNavigationItem}>
                  <Text size={Text.size.s}>Log out</Text>
                </Button>
                <Button
                  size="medium"
                  className={cx(s.headerContentTopContentNavigationItem, s.headerContentTopContentNavigationItemIcon)}
                  onClick={() => setSupportShown(true)}
                  startIcon={<HelpOutlineOutlinedIcon />}
                >
                  <Text size={Text.size.s}>Help</Text>
                </Button>
              </Box>
            </div>
          </div>
        </div>
        <div className={cx(s.headerContentNav, s.headerContentTopContent)}>
          <Breadcrumbs />
        </div>
      </div>

      {isSupportShown && <SupportPopup closeFunction={() => setSupportShown(false)} />}
    </header>
  );
};

// Header.propTypes = {
//   logout: PropTypes.func.isRequired,
//   isAdmin: PropTypes.bool,
//   isSuperAdmin: PropTypes.bool,
// };

Header.defaultProps = {
  isAdmin: false,
  isSuperAdmin: false,
};

export default Header;
