import React from 'react';
import Logo from '/logo-landing.svg?url';
import Overlay from '/overlay-landing.svg?url';
import s from './styles.module.scss';
import { Box, Button } from '@mui/material';
import { Text } from '~/ui/index.js';
import { createAuthURL } from '~/data/index.js';
import { authUrl } from '~/config';

export function LandingPage() {
  const SSO_URL = createAuthURL(authUrl);

  return (
    <div className={s.page}>
      <Box className={s.pageLeft}>
        <div>
          <img src={Logo} alt="Regulatory Reporting Insights" className={s.pageLogo} />
        </div>
        <Box className={s.pageLeftTextWrap}>
          <Box marginBottom="16px">
            <Text className={s.pageLeftTextTitle}>Regulatory Reporting Insights</Text>
          </Box>
          <Box marginBottom="88px">
            <Text className={s.pageLeftTextSubtitle}>{`It's nice to have you back.`}</Text>
          </Box>
          <Box>
            <Button component={'a'} href={SSO_URL} variant="contained" className={s.pageButton}>
              PWC SSO
            </Button>
          </Box>
        </Box>
      </Box>
      <div className={s.pageAccent}>
        <img src={Overlay} alt="overlay" className={s.pageOverlay} />
      </div>
    </div>
  );
}
