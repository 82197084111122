import { AnyPropsType } from '~/AnyPropsType';
import cx from 'classnames';
import React from 'react';

import s from './styles.module.scss';

const Container = ({ children, className, ...props }: AnyPropsType) => (
  <div {...props} id="container" className={cx(s.container, className)}>
    {children}
  </div>
);

// Container.propTypes = {
//   children: PropTypes.node,
// };

Container.defaultProps = {
  children: null,
};

export default Container;
