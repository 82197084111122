import Breadcrumbs from '~/app/components/core/breadcrumbs/index';
import ErrorPopup from '~/app/components/core/popup/api-error';
import { clientPath, clientsPagePath, clientsPath } from '~/app/constants/url/auditor';
import { selectors, useDispatch, useSelector } from '~/data/index.js';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { api } from '~/data/actions/index';

import { ClientReportsRouter } from './client/index';
import { Clients } from './clients/index';

export const ClientsRouter = () => {
  const dispatch = useDispatch();

  const apiError = useSelector(selectors.api.getError);
  const apiSuccess = useSelector(selectors.api.getSuccess);

  const resetApi = () => dispatch(api.reset());

  return (
    <>
      <Breadcrumbs.Anchor
        path={clientsPath}
        link={clientsPath}
        component={<span title="Client reports">Client reports</span>}
      />
      <Switch>
        <Route path={clientsPagePath(':page([1-9]\\d*)')} component={Clients} />
        <Route path={clientPath(':clientId')} component={ClientReportsRouter} />
        <Redirect from={clientsPath} to={clientsPagePath(1)} />
      </Switch>

      {!apiSuccess && <ErrorPopup error={apiError} closeFunction={resetApi} />}
    </>
  );
};
