import { approvalProgress, itemsPagination, listLoadingProgress, rejectionProgress } from '~/data/reducers/approvals';
import * as schemas from '~/data/utils/schemas';
import { Map } from 'immutable';
import { denormalize } from 'normalizr';

const getState = (store) => store.approvals;

const getEntities = (store) => getState(store).get('entities');

export const getItems = (store) => {
  const entities = Map({
    approvals: getEntities(store),
  });
  return denormalize(itemsPagination.getPageItems(getState(store)), [schemas.approval], entities);
};

export const itemsLoaded = (store) => listLoadingProgress.getLoaded(getState(store));

export const getTotalItems = (store) => itemsPagination.getTotalItems(getState(store));

export const getReportApproved = (store) => approvalProgress.getLoaded(getState(store));
export const getReportApproving = (store) => approvalProgress.getLoading(getState(store));
export const getReportApprovingFailed = (store) => approvalProgress.getLoadFailed(getState(store));

export const getReportRejected = (store) => rejectionProgress.getLoaded(getState(store));
export const getReportRejecting = (store) => rejectionProgress.getLoading(getState(store));
export const getReportRejectingFailed = (store) => rejectionProgress.getLoadFailed(getState(store));
